<template>
    <div id="courseIntroductionDetails">
        <div class="breadCrumbs">
                <span style="color: #999999;cursor: pointer;" @click="indexClick">个人中心</span>>
                <span style="color: #999999;cursor: pointer;" @click="courseClick">课程管理</span>>
                <span>上传课程</span>
            </div>
        <div class="tabClass">
            <div :class="tabIndex == index ? 'activeTab' : ''" style="cursor: pointer;" @click="tabClick(index)"
                v-for="(item, index) in tabData" :key="index">{{ item.title }}</div>
        </div>
        <div class="box">

            <!-- 悬浮按钮 -->
            <!-- <div style="width: 80px;height: 280px;display: flex;flex-direction: column;justify-content: space-evenly;align-items: center;position:fixed;right:3%;bottom:5%;">
                <div @click="cligocenterHUI()" style="cursor:pointer;width: 74px;height: 32px;background: #76C8F6;border-radius: 4px;display: flex;justify-content: space-evenly;align-items: center;">
                    <img :src="require('@/assets/img/ConstructionDetail/back.png')" style="width:13px; height:15px;" alt="">
                    <span style="font-size: 14px;color:#fff;">返回</span>
                </div>
                <div @click="cligocenterCUN()" style="cursor:pointer;width: 74px;height: 32px;background: #76C8F6;border-radius: 4px;display: flex;justify-content: space-evenly;align-items: center;">
                    <img :src="require('@/assets/img/ConstructionDetail/baocun.png')" style="width:13px; height:15px;" alt="">
                    <span style="font-size: 14px;color:#fff;">保存</span>
                </div>
                <div @click="cligocenterFA()" style="cursor:pointer;width: 74px;height: 32px;background: #76C8F6;border-radius: 4px;display: flex;justify-content: space-evenly;align-items: center;">
                    <img :src="require('@/assets/img/ConstructionDetail/fabu.png')" style="width:13px; height:15px;" alt="">
                    <span style="font-size: 14px;color:#fff;">发布</span>
                </div>
            </div> -->

            <!--  -->
            <div v-show="tabIndex == 0">
                <el-card style="width:100%; height:340px;margin-bottom:0.5%;">
                    <div style="display: flex;justify-content: flex-start;">
                        <el-upload v-if="!imgUrl" class="upload-demo"
                            :action="baseUrl + '/Training_API/api/UploadFile/MultiFileUpload?fileType=1'"
                            :on-success="handleAvatarSuccess" :on-exceed="handleExceed" accept=".jpg,.jpeg,.png"
                            :headers="headers" :show-file-list="false" :limit="1">
                            <div
                                style="width: 400px;height: 281px;display: flex;flex-direction: column;align-items: center;justify-content: center;">
                                <span style="display:flex;align-items:center;font-size:50px;color:#666;">+</span>
                                <div class="el-upload__tip" style="">
                                    <div style="font-size: 12px;color:#333;">
                                        点击上传课程封面图
                                    </div>
                                    <div style="font-size: 12px;color:#666;">
                                        图片不超2M，推荐尺寸836px*627
                                    </div>
                                </div>
                            </div>

                        </el-upload>
                        <div style="width: 500px;height: 281px;position: relative;" v-else>
                            <img style="width: 100%;height: 100%;" :src="baseUrl + '/' + imgUrl" alt="" srcset="">
                            <span style="position: absolute;top: 88%;right:2% ;z-index: 99;cursor: pointer;color: #76C8F6;"
                                @click="deleteClickImg">删除</span>
                        </div>
                        <div style="width:100%;height:320px;float: left;margin-left:2%;">
                            <el-input style="width: 338px;" v-model="courseName" class="w-50 m-2" size="large"
                                placeholder="请输入课程名称" />

                            <div
                                style="width: 100%;height: 230px;background: #F8F9FB;border-radius: 4px;margin-top:1%;overflow-y: auto;overflow-x: none;overflow-x: hidden;">
                                <div style="padding:1% 0 0 1%;margin-bottom:1%;">
                                    <span class="demonstration">课程上传时间：</span>
                                    <el-date-picker v-model="courDate" type="date" format="YYYY-MM-DD"
                                        value-format="YYYY-MM-DD" placeholder="选择日期" />
                                </div>

                                <div class="block" style="margin-bottom:1%;margin-top: 20px;">
                                    <el-form  label-width="120px" :inline="true">
                                        <el-form-item label="来源组织：" label-width="120px">
                                            <el-input style="width: 185px;" v-model="productionUnit" class="w-50 m-2"
                                                size="large" placeholder="请输入来源组织" />
                                        </el-form-item>
                                        <el-form-item label="类别：">
                                            <el-select v-model="category" placeholder="请选择类别" style="width: 185px;">
                                                <el-option v-for="item in categoryOptions" :key="item.value"
                                                    :label="item.label" :value="item.value">
                                                </el-option>
                                                <!-- <el-option label="高职" value="1" key="1" />
                                                <el-option label="本科" value="2" key="2" /> -->
                                            </el-select>
                                        </el-form-item>
                                        <el-form-item label="专业：">
                                            <el-select v-model="majorValue" placeholder="请选择专业" style="width: 185px;"
                                                @change="majorChange">
                                                <el-option v-for="item in majorOptions" :key="item.id"
                                                    :label="item.majorName" :value="item.id">
                                                </el-option>
                                            </el-select>
                                        </el-form-item>
                                        <el-form-item label="课程：">
                                            <el-select v-model="subjectValue" placeholder="请选择课程" style="width: 185px;">
                                                <el-option v-for="item in subjectOptions" :key="item.id"
                                                    :label="item.subjectName" :value="item.id">
                                                </el-option>
                                            </el-select>
                                        </el-form-item>
                                        <el-form-item label="作者：">
                                            <el-input style="width: 185px;" v-model="producer" class="w-50 m-2" size="large"
                                                placeholder="请输入作者" />
                                        </el-form-item>
                                        <el-form-item label="课程依据：">
                                            <el-input style="width: 185px;" v-model="pursuant" class="w-50 m-2" size="large"
                                                placeholder="请输入课程依据" />
                                        </el-form-item>
                                    </el-form>
                                </div>
                            </div>
                        </div>
                    </div>
                </el-card>

                <el-card style="width:100%; height:400px;margin-bottom:0.5%;">
                    <div style="border-bottom:1px solid #f0eeee;padding-bottom:1%;margin-bottom:0.5%;">
                        <span class="demonstration">课程详情</span>
                    </div>
                    <el-input v-model="courseDetails" :autosize="{ minRows: 2, maxRows: 4 }" type="textarea" />
                    <div style="text-align: center;">
                        <el-button type="primary" round
                            style="background-color: #76C8F6;border: none;margin-bottom: 20px;margin-top: 20px;width: 100px;"
                            @click="completeClick">完成</el-button>
                    </div>

                </el-card>
            </div>




            <!-- 课程目录 -->
            <div v-show="tabIndex == 1">
                <!-- 章 -->
                <el-card style="width:100%; height:100%;margin-bottom:0.5%;" v-show="chapterShow">
                    <el-button type="primary"
                        style="background-color: #76C8F6;border: none;margin-bottom: 10px;margin-top: 10px;float: right;"
                        @click="addchapter">添加章</el-button>
                    <el-table :data="tableDatazhang" stripe style="width: 100%;height:600px" max-height="894px">
                        <el-table-column type="index" label="序号" align="center" />
                        <el-table-column prop="isOrder" label="排序" align="center" />
                        <el-table-column prop="catalogueName" label="章名称" align="center" />
                        <el-table-column label="操作" align="center">
                            <template #default="scope">
                                <span style="cursor: pointer;color:#76C8F6;margin-right:20px"
                                    @click="editClick(scope.row)">编辑</span>
                                <span style="cursor: pointer;color:#76C8F6;margin-right:20px"
                                    @click="setupJoint(scope.row)">设置节</span>
                                <span style="cursor: pointer;color:#76C8F6;margin-right:20px;color: red;"
                                    @click="deleteClick(scope.row)">删除</span>
                            </template>
                        </el-table-column>
                    </el-table>
                    <div style="text-align: center;">
                        <el-pagination :current-page="currentPageZhiang" :page-size="pageSizeZhiang" background
                            layout="total, prev, pager, next, jumper" :total="totalZhang" @size-change="handleSizeChange"
                            @current-change="handleCurrentChange" />
                    </div>
                </el-card>
                <!-- 节 -->
                <el-card style="width:100%; height:100%;margin-bottom:0.5%;" v-show="!chapterShow">
                    <span class="classname" style="cursor: pointer;">
                        <el-icon size="20px" @click="blackZhang">
                            <ArrowLeftBold />
                        </el-icon>

                    </span>
                    <el-button type="primary"
                        style="background-color: #76C8F6;border: none;margin-bottom: 10px;margin-top: 10px;float: right;"
                        @click="addjoint">添加节</el-button>
                    <el-table :data="tableDataJoint" stripe style="width: 100%;height:800px" max-height="894px">
                        <el-table-column type="index" label="序号" align="center" />
                        <el-table-column prop="isOrder" label="排序" align="center" />
                        <el-table-column prop="catalogueName" label="节名称" align="center" />
                        <el-table-column label="操作" align="center">
                            <template #default="scope">
                                <span style="cursor: pointer;color:#76C8F6;margin-right:20px"
                                    @click="distrClick(scope.row)">{{scope.row.courseContentFile.filePath?'已添加课程内容':'添加课程内容'}}</span>
                                <span style="cursor: pointer;color:#76C8F6;margin-right:20px" @click="dialogVisibleClick(scope.row)">{{scope.row.courseExercises?'已配置习题':'配置习题'}}</span>
                                <span style="cursor: pointer;color:#76C8F6;margin-right:20px"
                                    @click="exerCiseSocreClick(scope.row)">{{scope.row.coursewareFiles.length == 0?'添加课件':'已添加课件'}}</span>
                                <span style="cursor: pointer;color:#76C8F6;margin-right:20px"
                                    @click="editClickJoint(scope.row)">编辑</span>
                                <span style="cursor: pointer;color:#76C8F6;margin-right:20px;color: red;"
                                    @click="deleteClickJoint(scope.row)">删除本节</span>
                            </template>
                        </el-table-column>
                    </el-table>
                    <div style="text-align: center;">
                        <el-pagination :current-page="currentPagejonit" :page-size="pageSizejoint" background
                            layout="total, prev, pager, next, jumper" :total="totaljoint"
                            @size-change="handleSizejointChange" @current-change="handleCurrentjointChange" />
                    </div>
                </el-card>
            </div>


            <!-- 教师团队 -->
            <div v-show="tabIndex == 2">
                <el-card style="width:100%;margin-bottom:0.5%;">
                    <div style="border-bottom:1px solid #f0eeee;padding-bottom:1%;margin-bottom:0.5%;">
                        <span class="demonstration">教师团队</span>
                    </div>

                    <div style="margin-bottom:1%;">
                        <div>
                            课程负责人
                        </div>
                        <div class="courseName" style="display:flex;align-items:center;width:max-content">
                            <div
                                style="display:flex;align-items:center;flex-direction:column;margin-top:20px;width:max-content">
                                <el-upload class="avatar-uploader"
                                    :action="baseUrl + '/Training_API/api/UploadFile/MultiFileUpload?fileType=1'"
                                    :on-success="techerSuccess" accept=".jpg,.jpeg,.png" :headers="headers"
                                    :on-remove="teacherhandleRemove" :show-file-list="false">
                                    <el-avatar :size="100" v-if="imageUrl" :src=baseUrlimageUrl />
                                    <el-icon v-else class="avatar-uploader-icon">
                                        <span style="display:flex;align-items:center;">+</span>
                                    </el-icon>
                                </el-upload>
                                <el-input style="width: 124px;margin-top:10px;" v-model="teacherhead" class="w-50 m-2"
                                    placeholder="请输入教师姓名" />
                            </div>
                            <div style="display:flex;align-items:start;flex-direction:column;">
                                <!-- <el-button size="small" style="width:100px;background: rgba(118,200,246,0.14); color:#76C8F6;border: 1px solid #76C8F6;">重新上传</el-button> -->
                                <span style="font-size: 12px;color: #999999;line-height: 18px;">
                                    请上传老师照片，尺寸100px*100px
                                </span>
                            </div>

                        </div>

                    </div>

                    <div>
                        <div>
                            教师团队
                        </div>
                        <div style="display:flex;align-items:center;flex-wrap:wrap">
                            <div style="" v-for="(item, index) in techerUploadArray" :key="index">

                                <div class="courseName" style="display:flex;align-items:center;width:max-content;">

                                    <div
                                        style="display:flex;align-items:center;flex-direction:column;margin-top:20px;width:max-content;margin-right:20px;">
                                        <div style="width: 60%;display: flex;justify-content: end;">
                                            <el-icon :size="20" style="cursor: pointer;" @click="deleteImgClick(index)">
                                                <Delete />
                                            </el-icon>
                                        </div>
                                        <el-upload class="avatar-uploader"
                                            :action="baseUrl + '/Training_API/api/UploadFile/MultiFileUpload?fileType=1'"
                                            :on-success="techerSuccessChange.bind(null, { 'index': index, 'data': item })"
                                            accept=".jpg,.jpeg,.png" :headers="headers" :show-file-list="false">
                                            <el-avatar :size="100" v-if="item.imageUrl" :src=item.imageUrl />
                                            <el-icon v-else class="avatar-uploader-icon">
                                                <span style="display:flex;align-items:center;">+</span>
                                            </el-icon>
                                        </el-upload>
                                        <el-input style="width: 124px;margin-top:10px;" v-model="item.teacherName"
                                            class="w-50 m-2" placeholder="请输入教师姓名" />
                                        <span style="font-size: 12px;color: #999999;line-height: 18px;">
                                            请上传老师照片，尺寸100px*100px
                                        </span>
                                    </div>
                                </div>
                            </div>


                            <div style="display:flex;align-items:start;flex-direction:column;">
                                <el-button size="small"
                                    style="width:100px;background: rgba(118,200,246,0.14); color:#76C8F6;border: 1px solid #76C8F6;"
                                    @click="addTeacher">增加教师位置</el-button>
                            </div>

                        </div>

                    </div>
                    <div style="text-align: center;">
                        <el-button type="primary" round
                            style="background-color: #76C8F6;border: none;margin-bottom: 20px;margin-top: 20px;width: 100px;"
                            @click="teacherAdd">完成</el-button>
                    </div>
                </el-card>
            </div>



            <!-- 课程思政 -->
            <div v-show="tabIndex == 3">

                <el-card style="width:100%; height: 100%;margin-bottom:0.5%;overflow-y: auto;">

                    <el-form  label-width="200px" :model="form2">
                        <el-form-item label="选择案例：">
                            <!-- height: 36px;border-radius: 18px;border: 1px solid #DDE1E6; -->
                            <el-select v-model="educationForm.caseSharingId" placeholder="请选择案例" @change="change">
                                <el-option v-for="item in caseShareData" :label="item.caseName" :value="item.id"
                                    :key="item.id" />
                            </el-select>
                        </el-form-item>
                        <el-form-item label="展示内容：" v-if="csColumnData.length != 0">
                            <el-checkbox-group v-model="cscolumsdata">
                                <el-checkbox v-for="item in csColumnData" :label="item.id" name="type" :key="item.id"
                                    @change="changecscolums($event, item.id)" >{{ item.title }}</el-checkbox>
                            </el-checkbox-group>
                        </el-form-item>
                        <el-form-item label="专业讲堂是否展示：">
                            <el-radio-group v-model="isShowcsMajorClassRoomdetermine" @change="showcscraft">
                                <el-radio :label="0">展示</el-radio>
                                <el-radio :label="1">不展示</el-radio>
                            </el-radio-group>
                        </el-form-item>
                        <el-form-item label="专业讲堂中展示内容：" v-show="isShowcsMajorClassRoomdetermine == 0">
                            <el-select v-model="educationForm.csMajorClassRoomIds" placeholder="" multiple>
                                <el-option v-for="item in csmajorroomData" :label="item.mcrName" :value="item.id"
                                    :key="item.id" />
                            </el-select>
                        </el-form-item>
                        <el-form-item label="思政亮点是否展示：">
                            <el-radio-group v-model="isShowcsGreatCountryCraftsmandetermine" @change="showcraftsman">
                                <el-radio :label="0">展示</el-radio>
                                <el-radio :label="1">不展示</el-radio>
                            </el-radio-group>
                        </el-form-item>
                        <el-form-item label="思政亮点：" v-show="isShowcsGreatCountryCraftsmandetermine == 0">
                            <!-- height: 36px;border-radius: 18px;border: 1px solid #DDE1E6; -->
                            <el-select v-model="educationForm.csGreatCountryCraftsmanIds" placeholder=""
                                multiple>
                                <el-option v-for="item in cscraftsmanData" :label="item.title" :value="item.id"
                                    :key="item.id" />
                            </el-select>

                        </el-form-item>
                    </el-form>
                    <div style="text-align: center;">
                        <el-button type="primary" round
                            style="background-color: #76C8F6;border: none;margin-bottom: 20px;margin-top: 20px;width: 100px;"
                            @click="confirmeducation">完成</el-button>
                    </div>
                </el-card>
            </div>

        </div>


        <!-- 老师个人中心上传课程弹窗 -->
        <el-dialog v-model="UploadDialog1" title="添加课程思政" width="30%" align-center>
            <div style="position:relative;padding:0 0 6% 0;">
                <el-form  label-width="200px" :model="form1" style="max-width: 660px">
                    <el-form-item label="选择案例：">
                        <!-- height: 36px;border-radius: 18px;border: 1px solid #DDE1E6; -->
                        <el-select v-model="form1.select1" placeholder="请选择案例" style="width: 286px;">
                            <el-option label="Restaurant" value="1" />
                            <el-option label="Order No." value="2" />
                            <el-option label="Tel" value="3" />
                        </el-select>
                    </el-form-item>
                    <el-form-item label="展示内容：">
                        <el-checkbox-group v-model="form1.type1">
                            <el-checkbox label="价值意义" name="type" />
                            <el-checkbox label="项目背景" name="type" />
                            <el-checkbox label="所获荣誉" name="type" />
                        </el-checkbox-group>
                    </el-form-item>
                    <el-form-item label="专业讲堂是否展示：">
                        <el-radio-group v-model="form1.resource1">
                            <el-radio label="展示" />
                            <el-radio label="不展示" />
                        </el-radio-group>
                    </el-form-item>
                    <el-form-item label="大国工匠是否展示：">
                        <el-radio-group v-model="form1.resource2">
                            <el-radio label="展示" />
                            <el-radio label="不展示" />
                        </el-radio-group>
                    </el-form-item>
                </el-form>
                <div style="width: 132px;height: 42px;border-radius: 21px;border: 1px solid #76C8F6;font-size: 14px;color: #76C8F6;
            position:absolute;left:22%;bottom:0%;display:flex;align-items:center;justify-content: center;
            ">
                    取消
                </div>
                <div style="width: 132px;height: 42px;background: #76C8F6;border-radius: 21px;font-size: 14px;color: #FFFFFF;
            position:absolute;right:22%;bottom:0%;display:flex;align-items:center;justify-content: center;
            ">
                    添加
                </div>
            </div>
        </el-dialog>

        <!-- 老师个人中心上传课程弹窗 -->
        <el-dialog v-model="UploadDialog2" title="添加课程思政" width="40%" align-center>
            <div style="position:relative;padding:0 0 6% 0;">
            </div>
            <template #footer>
                <div class="dialog-footer" style="text-align: center;">
                    <el-button round @click="hanClicktianqu()" style="border-color:#76C8F6; color: #76C8F6;">取消</el-button>
                    <el-button round style="background-color: #76C8F6;border: none;" type="primary"
                        @click="hanClicktianqu()">
                        确定
                    </el-button>
                </div>
            </template>
        </el-dialog>


        <!-- 上传课程封面图 -->
        <el-dialog v-model="UploadDialog4" title="上传课程封面图" width="22%" align-center>
            <div style="position:relative;padding:0 0 10% 0;">
                <el-upload style="border:1px solid #ebebeb;"
                    action="https://run.mocky.io/v3/9d059bf9-4660-45f2-925d-ce80ad6c4d15" multiple :limit="1">
                    <div
                        style="width: 495px;height: 281px;display: flex;flex-direction: column;align-items: center;justify-content: center;">
                        <div class="el-upload__tip"
                            style="display: flex;flex-direction: column;align-items: center;justify-content: center;">
                            <el-button type="primary" round style="background-color: #76C8F6;border: none;">选择图片</el-button>
                            <div style="font-size: 12px;color:#666;">
                                建议尺寸：394px*224px，10M以内，支持.jpg/.png格式，文件不超过1G
                            </div>
                        </div>
                    </div>
                </el-upload>

                <div style="width: 132px;height: 42px;border-radius: 21px;border: 1px solid #76C8F6;font-size: 14px;color: #76C8F6;
            position:absolute;left:22%;bottom:0%;display:flex;align-items:center;justify-content: center;
        ">
                    取消
                </div>
                <div style="width: 132px;height: 42px;background: #76C8F6;border-radius: 21px;font-size: 14px;color: #FFFFFF;
            position:absolute;right:22%;bottom:0%;display:flex;align-items:center;justify-content: center;
        ">
                    确定
                </div>
            </div>
            <template #footer>
                <div class="dialog-footer" style="text-align: center;">
                    <el-button round @click="UploadDialog4 = false"
                        style="border-color:#76C8F6; color: #76C8F6;">取消</el-button>
                    <el-button round style="background-color: #76C8F6;border: none;" type="primary"
                        @click="UploadDialog4 = false">
                        确定
                    </el-button>
                </div>
            </template>
        </el-dialog>

        <!-- 添加课程 一个 -->
        <el-dialog v-model="UploadDialog5" title="添加课程" width="22%" align-center>
            <div style="position:relative;padding:0 0 10% 0;">

                <el-table :data="tableData1" border style="width: 100%;margin-bottom:3%;">
                    <el-table-column prop="name" label="文件名称" align="center" />
                    <el-table-column label="操作" align="center">
                        <template #default>
                            <span style="cursor: pointer;color:#76C8F6" @click="uploadingCourse()">重新上传</span>
                        </template>
                    </el-table-column>
                </el-table>

                <!-- <el-upload
              v-model:file-list="fileList"
              action="https://run.mocky.io/v3/9d059bf9-4660-45f2-925d-ce80ad6c4d15"
              multiple
              :on-preview="handlePreview"
              :on-remove="handleRemove"
              :before-remove="beforeRemove"
              :limit="3"
              :on-exceed="handleExceed"
            > -->   
                <div class="line-content videoinputs">
                    <input v-show="tableData1.length==0" @change="addvideo($event, 1, item)" class="hiddeiput" name="file" id="file" type="file" style="width:20%;height: 40px;">
                    <el-button v-show="tableData1.length==0" type="primary"
                        style="background: rgba(118,200,246,0.14);border: 1px solid #76C8F6;color: #76C8F6;">上传课程</el-button>
                    <!-- <template #tip> -->
                    <div class="el-upload__tip">
                        可添加mp4/pdf/doc格式的文件，仅可添加一个，文件不超过1G
                    </div>
                    <!-- </template> -->
                    <!-- <input/> -->
                </div>
                <!-- </el-upload>  -->
            </div>
            <template #footer>
                <div class="dialog-footer" style="text-align: center;">
                    <el-button round @click="distrClickqu()" style="border-color:#76C8F6; color: #76C8F6;">取消</el-button>
                    <el-button round style="background-color: #76C8F6;border: none;" type="primary"
                        @click="distrClickque()">
                        确定
                    </el-button>
                </div>
            </template>
        </el-dialog>

        <!-- 添加课件 多个 -->
        <el-dialog v-model="UploadDialog6" title="添加课件" width="32%" align-center>
            <div style="position:relative;">

                <el-table :data="fileLists" border style="width: 100%;margin-bottom:3%;">
                    <el-table-column type="index" label="序号" align="center" width="80" />
                    <el-table-column prop="fileName" label="文件名称" align="center" />
                    <el-table-column label="操作" align="center">
                        <template #default="scope">
                            <span style="cursor: pointer;color:#F50000;margin-right:10%;"
                                @click.stop="deletecourseware(scope.row)">删除</span>
                        </template>
                    </el-table-column>
                </el-table>

                <!-- <div style="display: flex;justify-content: center;width: 100%;height: 40px;align-items: center;">
                    <el-pagination small v-model:current-page="currentPage4" layout="prev, pager, next" :total="23" />
                </div> -->
                
                    <el-upload v-show="fileLists.length==0"  class="upload-coursware"  ref="upload-demo" :action="baseUrl + '/Training_API/api/UploadFile/MultiFileUpload?fileType=3'" 
                        :before-upload="handleBeforeUpload" 
                        :on-remove="handleRemove" :on-change="handleFileChange" :on-success="handleFileSuccess"
                        :headers="headers" multiple :show-file-list="false">
                        <el-button type="primary"
                            style="background: rgba(118,200,246,0.14);border: 1px solid #76C8F6;color: #76C8F6;">上传课件</el-button>
                            <template #tip>
                                <div class="el-upload__tip">
                            可添加word/pdf/ppt文档,文件不超过1G
                        </div>
                        </template>
                       
                    </el-upload>
                    <span  v-show="fileLists.length>0">可添加word/pdf/ppt文档,文件不超过1G</span>
                
            </div>
            <template #footer>
                <div class="dialog-footer" style="text-align: center;">
                    <el-button round @click="exerCiseSocreClickqu()"
                        style="border-color:#76C8F6; color: #76C8F6;">取消</el-button>
                    <el-button round style="background-color: #76C8F6;border: none;" type="primary"
                        @click="exerCiseSocreClickque()">
                        确定
                    </el-button>
                </div>
            </template>
        </el-dialog>

        <el-dialog v-model="addZhang" :title="titleZhang" width="22%" align-center :show-close="false">
            <el-form ref="ruleFormRef" :model="ruleForm" :rules="rules" label-width="120px" class="demo-ruleForm" style="margin-right:10px">
                <el-form-item label="排序" prop="sortNum">
                    <el-input v-model="ruleForm.sortNum" placeholder="请输入" />
                    
                </el-form-item>
                <el-form-item label="">
                    <span style="color: red;font-size: 12px;">*排序可输入1-4位数字，数字越小越靠前，推荐使用1
                        00，200，300等数字进行排列，方便改变排序</span>
                </el-form-item>
                <el-form-item label="章名称" prop="zhangname">
                    <el-input v-model="ruleForm.zhangname" placeholder="请输入" />
                </el-form-item>
            </el-form>
            <template #footer>
                <div class="dialog-footer" style="text-align: center;">
                    <el-button round @click="quxiao()" style="border-color:#76C8F6; color: #76C8F6;">取消</el-button>
                    <el-button round style="background-color: #76C8F6;border: none;" type="primary" @click="wancheng()">
                        确定
                    </el-button>
                </div>
            </template>
        </el-dialog>
        <el-dialog v-model="addjointShow" :title="titlejoint" width="22%" align-center :show-close="false">
            <el-form ref="ruleFormRefjoint" :model="ruleFormjoint" :rules="rulesjoint" label-width="120px"  style="margin-right:10px"
                class="demo-ruleForm">
                <el-form-item label="排序" prop="sortNum">
                    <el-input v-model="ruleFormjoint.sortNum" placeholder="请输入" />
                    
                </el-form-item>
                <el-form-item label="" >
                    <span style="color: red;font-size: 12px;">*排序可输入1-4位数字，数字越小越靠前，推荐使用1
                        00，200，300等数字进行排列，方便改变排序</span>
                </el-form-item>
                
                <el-form-item label="节名称" prop="zhangname">
                    <el-input v-model="ruleFormjoint.zhangname" placeholder="请输入" />
                </el-form-item>
            </el-form>
            <template #footer>
                <div class="dialog-footer" style="text-align: center;">
                    <el-button round @click="quxiaojoint()" style="border-color:#76C8F6; color: #76C8F6;">取消</el-button>
                    <el-button round style="background-color: #76C8F6;border: none;" type="primary"
                        @click="wanchengjoint()">
                        确定
                    </el-button>
                </div>
            </template>
        </el-dialog>
 <!-- 配置习题 -->
 <el-dialog v-model="dialogVisible" title="配置习题" width="75%" :show-close="false">
    <div v-if="dialogVisible">

   
    <el-form ref="rulesexercisesRef" :model="form" label-width="130px" size="mini" :rules='rulesexercises'>
                <el-form-item label="章名称：">
                    {{ zhangName }}
                </el-form-item>
                <el-form-item label="节名称：">
                    {{ jieName }}
                </el-form-item>
                <el-form-item label="习题名称：" prop="exercisesnName">
                    <el-input style="width: 200px;margin-left: 0px;" show-word-limit minlength='2' maxlength='20' v-model="form.exercisesnName" placeholder="请输入习题名称" />
                </el-form-item>
                <!-- <el-form-item label="习题截止时间：" prop="exercisesnDate">
                        <el-date-picker
                        v-model="form.exercisesnDate"
                        type="datetimerange"
                        range-separator="至"
                        start-placeholder="开始时间"
                        end-placeholder="结束时间"
                        format="YYYY-MM-DD HH:mm:ss"
                        value-format="YYYY-MM-DD HH:mm:ss"
                        :disabled-date="disabledDate"
                        />
                </el-form-item> -->
                <p style="font-weight: 600;padding: 20px;">配置习题</p>
                <div v-for="(item,index) in form.domains" :key="index" style="display: flex;flex-wrap: wrap;justify-content: flex-start;width: 100%;">
                <el-form-item label="章名称" label-width="80px" :prop="'domains.' + index + '.zhangValue'" :rules="rulesexercises.zhangValue">
                    <el-select
                    size="mini"
                    @change="zhangChange(index)"
                        v-model="item.zhangValue"
                        placeholder="请选择章名称"
                        clearable>
                        <el-option
                        v-for="item in zhangOptions"
                        :key="item.id"
                        :label="item.catalogueName"
                        :value="item.id"
                        />
                    </el-select>
                </el-form-item>
                <el-form-item label="节名称" label-width="80px" :prop="'domains.' + index + '.jieValue'" :rules="rulesexercises.jieValue">
                    <el-select
                        @change="jieChange(index)"
                        @visible-change="visibleChange(index)"
                        size="mini"
                        v-model="item.jieValue"
                        placeholder="请选择节名称"
                        clearable>
                        <el-option
                        v-for="item in jieOptions"
                        :key="item.id"
                        :label="item.catalogueName"
                        :value="item.id"
                        />
                    </el-select>
                </el-form-item>
                <el-form-item label="选择题型" label-width="80px" :prop="'domains.' + index + '.tixingValue'" :rules="rulesexercises.tixingValue">
                    <el-select
                    @change="tixingChange(index)"
                    size="mini"
                        v-model="item.tixingValue"
                        placeholder="请选择题型"
                        clearable>
                        <el-option
                        v-for="item in tixingOptions"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                        />
                    </el-select>
                </el-form-item>
                <el-form-item label="选择题库难度" label-width="120px" :prop="'domains.' + index + '.nanduValue'" :rules="rulesexercises.nanduValue">
                    <el-select
                    @change="nanduChange(index)"
                    size="mini"
                        v-model="item.nanduValue"
                        placeholder="请选择题库难度"
                        clearable>
                        <el-option
                        v-for="item in nanduOptions"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                        />
                    </el-select>
                </el-form-item>
                <span style="font-size: 14px; line-height: 30px; margin-left: 10px;margin-right: 10px;">当前题库符合条件试题数：{{ item.Eligible }}</span>
                <el-form-item label="填写试题数量" label-width="110px" :prop="'domains.' + index + '.stNum'" :rules="rulesexercises.stNum">
                    <el-input size="mini" v-model="item.stNum" @blur="stNumBlur" placeholder="请填写试题数量" clearable @input="stNumInput(item)" />
                </el-form-item>
                <span type="primary"  style="cursor: pointer;margin-left: 10px;" @click="deleteClickData(index)">X</span>
                </div>
                <el-button type="primary" round style="background-color: #76C8F6;border: none;margin-left: 20px;" size="mini" @click="addFrom">新增</el-button>
            </el-form>
           

            <p style="font-weight: 600;padding: 20px;">配置规则</p>
            <el-radio-group v-model="radio1" class="ml-4" style="margin-left: 20px;" @change="radioChange">
                <el-radio label="1" size="large">随机组卷</el-radio>
                <el-radio label="2" size="large">统一试卷</el-radio>
            </el-radio-group>
            <p v-if="radio1 == 1" style="color: red;font-size: 12px;padding-left: 20px;padding-top: 10px;">*随机组卷为基于配置规则及试题数量，随机抽取试题，每个学生的试卷不完全一样</p>
            <p v-else style="color: red;font-size: 12px;padding-left: 20px;padding-top: 10px;">*统一试卷为系统基于配置规则，生成试卷，可在预览后进行确认，每个学生的试卷完全一样</p>
            <el-button  v-if="radio1 == 2" style="margin: 20px;" type="primary" size="mini" plain @click="testPaper">试卷预览</el-button>
        </div>
            <template #footer>
            <div class="dialog-footer" style="text-align: center;">
                <el-button  @click="changeDialog">取消</el-button>
                <el-button style="background-color: #76C8F6;border: none;" type="primary" @click="insterClick">
                确定
                </el-button>
            </div>
            </template>
        </el-dialog>
         <!-- 试卷预览 -->
         <el-dialog
            v-model="examPreview"
            title="试卷预览"
            width="75%"
            :before-close="examPreviewClose">
            <div class="system">
               
               <div style="width: 100%;height: 700px;overflow-y: auto;">
                   <p style="font-weight: 600;">一、填空题</p>
                   <div style="margin-top: 10px;background-color: #fff;padding: 10px;" v-for="(item,index) in tiankongList" :key="index+'index'">
                     <div v-if="item.topicType == 3">
                         <div style="margin-top: 10px;font-size: 13px;">
                             <p style="margin-bottom: 5px;"><span style="display: inline-block;background-color: #76C8F6 ;padding-left: 5px;padding-right: 5px;color: #fff;border-radius: 3px;">{{ index+1 }}</span> {{ item.testQuestionContent }}  
                                 <el-button
                                     style="border-color: #76c8f6; color: #76c8f6;margin-top: 10px;margin-left:10px ;"
                                     size="mini"
                                     @click="tiankongQuestions(item,index)"
                                     >替换试题</el-button>
                                 </p>
                         </div>
                         
                     </div>
                   </div>
                  
                  
                  
                   <p style="font-weight: 600;padding-top: 10px;">二、单选题</p>
                   <div style="margin-top: 10px;background-color: #fff;padding: 10px;" v-for="(danxuan,indexdx) in danxuanList" :key="indexdx+'indexdx'">
                     <div v-if="danxuan.topicType == 1">
                         <div style="margin-top: 10px;font-size: 13px;">
                             <p style="margin-bottom: 5px;"><span style="display: inline-block;background-color: #76C8F6 ;padding-left: 5px;padding-right: 5px;color: #fff;border-radius: 3px;">{{ indexdx+1 }}</span> {{ danxuan.testQuestionContent }}  
                                 <el-button
                                     style="border-color: #76c8f6; color: #76c8f6;margin-top: 10px;margin-left:10px ;"
                                     size="mini"
                                     @click="duanxuanQuestions(danxuan,indexdx)"
                                     >替换试题</el-button>
                                 </p>
                             <el-radio-group >
                                 <el-radio  v-for="(item1,index1) in danxuan.answers" :key="index1" :label="item1.isAnswer">{{item1.isOrder == 1?'A':item1.isOrder == 2?'B':item1.isOrder == 3?'C':item1.isOrder == 4?'D':''}} {{ item1.content }}</el-radio>
                             </el-radio-group>
                         </div>
                         
                     </div>
                   </div>
                  

                   <p style="font-weight: 600;padding-top: 10px;">三、多选题</p>
                   <div style="margin-top: 10px;background-color: #fff;padding: 10px;" v-for="(duoxuan,indexduoxuan) in duoxuanList" :key="indexduoxuan+'indexduoxuan'">
                     <div v-if="duoxuan.topicType == 2">
                         <div style="margin-top: 10px;font-size: 13px;">
                             <p style="margin-bottom: 5px;"><span style="display: inline-block;background-color: #76C8F6 ;padding-left: 5px;padding-right: 5px;color: #fff;border-radius: 3px;">{{ indexduoxuan+1 }}</span> {{ duoxuan.testQuestionContent }}  
                                 <el-button
                                     style="border-color: #76c8f6; color: #76c8f6;margin-top: 10px;margin-left:10px ;"
                                     size="mini"
                                     @click="duoxuanQuestions(duoxuan,indexduoxuan)"
                                     >替换试题</el-button>
                                 </p>
                                 <el-radio-group >
                                     <el-radio  v-for="(item3,index4) in duoxuan.answers" :key="index4+'index4'" :label="item3.isAnswer">{{item3.isOrder == 1?'A':item3.isOrder == 2?'B':item3.isOrder == 3?'C':item3.isOrder == 4?'D':''}} {{ item3.content }}</el-radio>
                                 </el-radio-group>
                             
                         </div>
                         
                     </div>
                   </div>


                   <p style="font-weight: 600;padding-top: 10px;">四、判断题</p>
                   <div style="margin-top: 10px;background-color: #fff;padding: 10px;" v-for="(itempanduan,indexpanduan) in panduanList" :key="indexpanduan+'indexindexpanduan'">
                     <div v-if="itempanduan.topicType == 4">
                         <div style="margin-top: 10px;font-size: 13px;">
                             <p style="margin-bottom: 5px;"><span style="display: inline-block;background-color: #76C8F6 ;padding-left: 5px;padding-right: 5px;color: #fff;border-radius: 3px;">{{ indexpanduan+1 }}</span> {{ itempanduan.testQuestionContent }}  
                                 <el-button
                                     style="border-color: #76c8f6; color: #76c8f6;margin-top: 10px;margin-left:10px ;"
                                     size="mini"
                                     @click="panduanQuestions(itempanduan,indexpanduan)"
                                     >替换试题</el-button>
                                 </p>
                             <el-radio-group >
                                 <el-radio  v-for="(item5,index2) in itempanduan.answers" :key="index2" :label="item5.isAnswer">{{item5.isOrder == 1?'A':item5.isOrder == 2?'B':item5.isOrder == 3?'C':item5.isOrder == 4?'D':''}} {{ item5.content }}</el-radio>
                             </el-radio-group>
                         </div>
                         
                     </div>
                   </div>
               </div>
    
               <div style="text-align: center;" class="dialog-footer">
                 
                   <el-button type="primary" style="background-color: #76C8F6;border: none;margin-bottom: 20px;" round @click="confirmClick">
                   确认试题
                   </el-button>
               </div>
            
               
           </div>
        </el-dialog>
        <!-- 替换试题 -->
        <el-dialog
        title="替换试题"
        v-model="dialogVisibletihuan" width="50%"  :modal="false"
        :before-close="handleClosetihuan">
         <div class="searchList">
            <el-form :inline="true" :model="formInline" class="demo-form-inline">
              <el-form-item label="章名称:" @change="zhangChange2">
                <el-select
                  v-model="zhangValue"
                  placeholder="请选择章名称"
                  clearable
                >
                <el-option
              v-for="item in zhangOptions"
              :key="item.id"
              :label="item.catalogueName"
              :value="item.id"
            />
                </el-select>
              </el-form-item>
              <el-form-item label="节名称:">
                <el-select
                  v-model="jieValue"
                  placeholder="请选择节名称"
                  clearable
                >
                <el-option
              v-for="item in jieOptions"
              :key="item.id"
              :label="item.catalogueName"
              :value="item.id"
            />
                </el-select>
              </el-form-item>

              <el-form-item label="难度:">
                <el-select
                  v-model="nanduValue"
                  placeholder="请选择难度"
                  clearable
                >
                <el-option
              v-for="item in nanduOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
                </el-select>
              </el-form-item>
              <el-form-item label="上传人:">
                <el-input v-model="upDataName" placeholder="请输入上传人" clearable />
              </el-form-item>
              <el-form-item label="题干:">
                <el-input v-model="stemData" placeholder="请输入题干" clearable />
              </el-form-item>
              <el-form-item>
                <el-button size="small" type="primary"  @click="onSubmit">搜索</el-button>
              </el-form-item>
             
            
            </el-form>
            <div class="searchButton">

            </div>
          </div>
          <el-table :data="tableData" stripe style="width: 100%;height:500px" max-height="394px">
            <el-table-column fixed type="index"  label="序号" />

            <el-table-column prop="catalogueName" label="章名称"  />
            <el-table-column prop="sectionName" label="节名称" />
    
            <el-table-column prop="chapters" label="难度"  width="110" >
              <template #default="scope">
            <span type="text">{{ scope.row.topicDifficulty == 1?'简单':scope.row.topicDifficulty == 2?'中等':'困难' }}</span>
          </template>
            </el-table-column>
            <el-table-column prop="testQuestionContent" label="题干" width="150" />
            <el-table-column prop="correctAnswer" label="正确答案" width="150" />
            <el-table-column prop="testQuestionAnalysis" label="解析" width="150" />
            <el-table-column prop="insertTime" label="上传时间" width="200" />
            <el-table-column prop="realName" label="上传人" />
            <el-table-column  label="操作" fixed="right" width="160" >
              <template #default="scope">
                  <el-button type="text" @click="deselect(scope.row)" v-if="testQuestionId != scope.row.oldId && oldId != scope.row.oldId">{{scope.row.select == 1?'已选择':'选择'}}</el-button>
                  <span v-else>已选择</span>
              </template>
            </el-table-column>
          </el-table>
          <div style="display: flex;justify-content: center;width: 100%;height: 40px;align-items: center;">
          <el-pagination v-if="total>5" style="margin: 0 auto;text-align: center;"  :current-page="currentPageList" layout="prev, pager, next" :total="totallist"  @current-change="handleCurrentChangelist" />
            </div>
        <div style="text-align: center;margin-top: 20px;">
            <el-button type="primary" round style="background-color: #76C8F6;border: none;margin-left: 10px;" @click="cancellation">确定</el-button>
        </div>
        
        </el-dialog>


    </div>
</template>

<script>
import { reactive, toRefs, onMounted, onUnmounted, computed, watch, ref, components, nextTick } from "vue";
import { useRouter, useRoute } from "vue-router";
import { useStore } from "vuex";
import { getStorage, setStorage } from "@/js/common";
import { Plus, ArrowLeftBold, Delete } from '@element-plus/icons-vue';
import { baseUrl } from '@/js/util.js';
import axios from "axios";
import { guid, f_insert_courseContentFile } from '@/js/homeListApi';
import { get_select_csMajorClassRoom,get_select_csGreatCountryCraftsman,f_insert_courseWareFile,get_select_csColumn,DeleteFiles, get_select_majorList, get_select_subjectList, f_update_course, f_update_courseChapter, f_get_courseCatalogue, f_delete_courseCatalogue, f_update_courseSection, f_update_courseTeacherTeam, f_get_courseTeacherTeamList, get_select_caseSharing,f_update_courseIdeologyPolitics,f_get_courseIdeologyPolitics } from "@/js/courseManagementApi"
import { ElMessage, ElMessageBox, ElLoading } from "element-plus";
import { get_select_courseChapterList,get_select_courseSectionList,get_topicCount,f_intsert_generatePreviewPapers,f_update_replaceQuestions } from '@/js/classManagement'
import {get_select_questionList,f_update_courseExercises,f_get_courseExercises} from '@/js/teachingSquare'
export default {
    setup() {
        const router = useRouter();
        const route = useRoute();
        const store = useStore();
        const ruleFormRef = ref(null);
        const ruleFormRefjoint = ref(null);
        const rulesexercisesRef = ref(null)
        const state = reactive({
            isAnswer:true,
            topicType:'',
            testQuestionId:'',
            questionsIsoder:'',
            examinationPaperId:'',
            oldId:'',
            watchTitle:'',
            questionsIndex:'',
            zhangValue:'',
            jieValue:'',
            nanduValue:1,
            upDataName:'',
            stemData:'',
            currentPageList:1,
            totallist:0,
            questionsArray:[],
            questionsList :[],
            danxuanList:[],
            duoxuanList:[],
            tiankongList:[],
            panduanList:[],
            loading:null,
            categoryOptions:[
                {
                    label:'高职',
                    value:1
                },{
                    label:'本科',
                    value:2
                }
            ],
            examPreview:false,
            dialogVisibletihuan:false,
            zhangName:'',
            jieName:'',
            jieId:'',
            form:{
                exercisesnName:'',
                exercisesnDate:[],
                domains:[
                    {
                        zhangValue:'',
                        jieValue:'',
                        tixingValue:'',
                        nanduValue:'',
                        Eligible:0,
                        stNum:'',

                    }
                ],
            },
            rulesexercises:{
                exercisesnName:[{ required: true,message:'请输入作业名称',trigger: 'blur' }],
                zhangValue: [
                { required: true, message: "请选择章", trigger: "change" },
                ],
                jieValue: [{ required: true, message: "请选择章", trigger: "change" }],
                tixingValue: [
                { required: true, message: "请选择题型", trigger: "change" },
                ],
                nanduValue: [
                { required: true, message: "请选择难度", trigger: "change" },
                ],
                stNum: [{ required: true, message: "请输入试题数量", trigger: "blur" }],
            },
            zhangOptions:[],
            jieOptions:[],
            tixingOptions: [
                {
                value: 1,
                label: "单选",
                },
                {
                value: 2,
                label: "多选",
                },
                {
                value: 3,
                label: "填空",
                },
                {
                value: 4,
                label: "判断",
                },
            ],
            nanduOptions: [
                {
                value: 1,
                label: "简单",
                },
                {
                value: 2,
                label: "中等",
                },
                {
                value: 3,
                label: "困难",
                },
            ],
            percent: 0,
            dialogVisible:false,
            progressFlag: "",
            videourls: "",
            baseUrl: baseUrl,
            headers: {
                token: getStorage('ftoken'),
                operate: true
            },
            imgUrl: '',
            addZhang: false,
            coverPictureId: '',
            chapterShow: true,
            techerUploadArray: [
                {
                    imageUrl: '',
                    teacherimgUrlId: '',
                    teacherName: '',
                }
            ],
            ruleForm: {
                sortNum: '',
                zhangname: '',
            },
            rules: {
                sortNum: [{ required: true, message: '请输入排序', trigger: 'blur' }],
                zhangname: [{ required: true, message: '请输入章名称', trigger: 'blur' }]
            },
            ruleFormjoint: {
                sortNum: '',
                zhangname: '',
            },
            rulesjoint: {
                sortNum: [{ required: true, message: '请输入排序', trigger: 'blur' }],
                zhangname: [{ required: true, message: '请输入章名称', trigger: 'blur' }]
            },
            courDate: '',
            textarea2: '',
            majorValue: '',
            addjointShow: false,
            titlejoint: '',
            currentPageZhiang: 1,
            pageSizeZhiang: 10,
            currentPagejonit: 1,
            pageSizejoint: 10,
            totalZhang: 0,
            totaljoint: 0,
            majorOptions: [],
            subjectValue: '',
            teacherhead: '',
            subjectOptions: [],
            imageUrl: '',
            teacherimgUrlId: [],
            radio1: '1',
            baseUrlimageUrl: '',
            tabData: [
                {
                    title: '基本信息'
                }, {
                    title: '课程目录'
                }, {
                    title: '教师团队'
                }, {
                    title: '课程思政'
                }
            ],
            afterData: [
                {
                    renwu: '第一章',
                    modelNmae: '模块1 结论',
                    children: [
                        {
                            renwu: '第一节',
                            renwuCenter: '建筑力学的研究对象'
                        }, {
                            renwu: '第二节',
                            renwuCenter: '建筑力学的研究任务'
                        }, {
                            renwu: '第三节',
                            renwuCenter: '建筑力学的研究方向'
                        }

                    ]
                }, {
                    renwu: '第二章',
                    modelNmae: '模块2 静力学基础知识',
                },
            ],
            domains2: [
                {
                    user: '',
                    region: ''
                },
            ],
            UploadDialog1: false,//老师个人中心上传课程弹窗
            form1: {
                select1: '',
                type1: [],
                resource1: '',
                resource2: '',

            },
            UploadDialog2: false,//老师个人中心上传课程弹窗
            form2: {
                select1: '',
                type1: [],
                type2: [],
                resource1: 1,
                resource2: 1,

            },
            UploadDialog3: false,//配置习题
            form3: {
                select1: '',
                select2: '',
                select3: '',
                select4: '',
                select5: '',
                select6: '',
                select7: '',
                select8: '',
                resource1: '',
                num1: '',
                num2: '',

            },
            UploadDialog4: false,//上传课程封面图
            UploadDialog5: false,//添加课程
            tableData: [

            ],
            tableDatazhang: [],
            questionsRow:{},
            tableDataJoint: [],
            distrCourseSectionId6: '',
            tableData1: [],
            UploadDialog6: false,//添加课件
            tableData2: [],
            currentPage4: 0,
            tabIndex: '',
            imgUrlId: [],
            courseName: '',
            productionUnit: '',
            category: '',
            producer: '',
            pursuant: '',
            courseDetails: '',
            courseId: '',
            titleZhang: '',
            zhangid: '',
            parentId: '',
            itemRow: '',
            rowJointId: '',
            csColumnData: [],//展示内容
            cscraftsmanData: [],//专业讲堂
            csmajorroomData: [],//思政亮点
            caseShareData: [],
            cscolumsdata: [],
            isShowcsMajorClassRoomdetermine: 0,
            isShowcsGreatCountryCraftsmandetermine: 0,
            educationForm: {
                id: '',
                insertTime: new Date(),
                courseId: '',
                caseSharingId: '',
                csColumnsIds: [],
                isShowcsMajorClassRoom: '',
                csMajorClassRoomIds: [],
                isShowcsGreatCountryCraftsman: '',
                csGreatCountryCraftsmanIds: []
            },
            coursewareId: '',//添加课程节id
            file: '',
            fileLists: [],
            titleName:''
        });
        onMounted(() => {
            methods.get_select_majorList();
            methods.get_select_subjectList();

            console.log(route.query.itemRow);
            if (route.query.itemRow) {
                let itemRow = JSON.parse(route.query.itemRow)
                console.log(itemRow, 'itemRow')
                state.imgUrl = itemRow.file.filePath;
                state.imgUrlId.push(itemRow.file.Id);
                state.coverPictureId = itemRow.file.Id;
                state.courseName = itemRow.courseName;
                state.courDate = itemRow.publishTime;
                state.productionUnit = itemRow.productionUnit;
                state.category = itemRow.courseType;
                state.majorValue = itemRow.majorId;
                state.subjectValue = itemRow.subjectId;
                state.producer = itemRow.producer;
                state.pursuant = itemRow.pursuant;
                state.courseDetails = itemRow.courseDetails;
                state.courseId = itemRow.id;
                state.educationForm.courseId = itemRow.id
                // state.educationForm.id = itemRow.id
                setStorage('courseId', state.courseId);
            }
            
            methods.get_select_caseSharing();
            
        })
        onUnmounted(() => {
            setStorage('courseId', '')
        })
        const methods = {
            stNumInput(item){
                console.log(item);
                state.titleName = '修改';
                if (item.stNum > item.Eligible) {
                    ElMessage({
                                    message: "试题数量不能超过题库数量",
                                    type: "error",
                                    showClose: true,
                                    });
                     item.stNum = 0;
                }
            },
            indexClick() {
                setStorage("titleIndex",0)
                router.push('/home/courseList');
            },
            courseClick() {
                router.push('/home/courseList');
            },
            // 作业数据回填
            f_get_courseExercises(){
                let params = {
                    courseSectionId:state.jieId,
                    teacherId:getStorage('userId'),
                    classNumId:null,
                    exercisesType:1
                }
                f_get_courseExercises(params).then(res=>{
                    if (res.data.code == 1) {
                        state.form.exercisesnName = res.data.data.courseExercises.exercisesnName;
                        state.form.exercisesnDate[0] = res.data.data.courseExercises.startTime;
                        state.form.exercisesnDate[1] = res.data.data.courseExercises.endTime;
                        state.radio1 = res.data.data.courseExercises.generativeRule.toString();
                        state.form.id = res.data.data.courseExercises.id;
                        state.form.domains = [];
                        res.data.data.examinationGenerativeRuleList.forEach((element,index) => {
                            state.form.domains.push({
                                zhangValue:element.courseChapterId,
                                jieValue:element.courseSectionId,
                                tixingValue:element.topicType,
                                nanduValue:element.topicDifficulty,
                                stNum:element.topicCount,
                                id: element.id
                            })
                            // state.form.domains[index].zhangValue = element.courseChapterId;
                            // state.form.domains[index].jieValue = element.courseSectionId;
                            // state.form.domains[index].tixingValue = element.topicType;
                            // state.form.domains[index].nanduValue = element.topicDifficulty;
                            // state.form.domains[index].stNum = element.topicCount;
                            // state.form.domains[index].id = element.id;
                            methods.get_select_courseSectionList(
                            state.form.domains[index].zhangValue
                            );
                            methods.get_topicCount(
                            state.form.domains[index].tixingValue,
                            state.form.domains[index].nanduValue,
                            state.courseId,
                            state.form.domains[index].zhangValue,
                            state.form.domains[index].jieValue,
                            index
                        );
                        });
                               state.questionsList = [];
                               state.danxuanList = [];
                               state.duoxuanList = [];
                               state.tiankongList = [];
                               state.panduanList = [];
                               if (res.data.data.examinationPaper) {
                                res.data.data.examinationPaper.questions.forEach(element => {
                            element.forEach(element => {
                                state.questionsList.push(element)
                            });
                        });
                        state.examinationPaperId = res.data.data.examinationPaper.id;
                        state.watchTitle = '编辑';
                               }
                        
                               
                        state.questionsList.forEach(element => {
                                console.log(element);
                              
                                    if (element.topicType == 1) {
                                        element.answers = element.examinationPaperAnswers;
                                        delete element.examinationPaperAnswers;
                                       state.danxuanList.push(element);
                                       console.log(state.danxuanList);
                                   }else if(element.topicType == 2){
                                    element.answers = element.examinationPaperAnswers;
                                    delete element.examinationPaperAnswers;
                                       state.duoxuanList.push(element);
                                   }else if(element.topicType == 3){
                                    element.answers = element.examinationPaperAnswers;
                                    delete element.examinationPaperAnswers;
                                       state.tiankongList.push(element)
                                   }else{
                                    element.answers = element.examinationPaperAnswers;
                                    delete element.examinationPaperAnswers;
                                       state.panduanList.push(element)
                                       console.log(state.panduanList);
                                   }
                                
                                       
                                   
                                   
                               });
                    }else{
                        if ( res.data.message !="数据不存在") {
                            ElMessage({
                            type: 'error',
                            message: res.data.message,
                        })
                        }
                        
                    }
                })
            },
            // 保存
            insterClick(){
                if (state.radio1 == 2) {
                    if (state.titleName == '修改') {
                    return ElMessage({
                                    message: "请重新生成试卷",
                                    type: "info",
                                    showClose: true,
                                    });
                }
                }
                rulesexercisesRef.value.validate((valid) => {
                    if (valid) {
                        let arraylist = [];
                state.form.domains.forEach(element => {
                    arraylist.push({
                        courseChapterId:element.zhangValue,
                        courseSectionId:element.jieValue,
                        topicType:element.tixingValue,
                        topicDifficulty:element.nanduValue,
                        topicCount:element.stNum,
                        id:element.id
                    })
                });
                let params = {
                    id:state.form.id,
                    exercisesType:1,
                    courseId:state.courseId,
                    courseChapterId:state.parentId,
                    courseSectionId:state.jieId,
                    classNumId:'',
                    exercisesnName:state.form.exercisesnName,
                    generativeRule:state.radio1,
                    userId: getStorage('userId'),
                    examinationGenerativeRules:arraylist,
                    questions:state.radio1 == 1?[]:state.questionsList
                }
                f_update_courseExercises(params).then(res=>{
                    if (res.data.code == 1) {
                        ElMessage({
                            type: 'success',
                            message: '成功',
                        })
                        state.dialogVisible = false;
                        state.form = {
                        exercisesnName:'',
                        exercisesnDate:[],
                        domains:[
                            {
                                zhangValue:'',
                                jieValue:'',
                                tixingValue:'',
                                nanduValue:'',
                                Eligible:0,
                                stNum:'',

                            }
                        ],
                    };
                        methods.f_get_courseCatalogueJoint()
                    }else{
                        ElMessage({
                            type: 'error',
                            message: res.data.message,
                        })
                    }
                })
                    }
                })

            },
             // 确认
             confirmClick(){
                state.titleName = '';
                state.watchTitle = '';
            state.questionsList = [];
            state.questionsList = state.questionsList.concat(state.danxuanList,state.duoxuanList,state.tiankongList,state.panduanList);
            state.examPreview = false;
            console.log(state.questionsList);
        },
         // 
         radioChange(event){
                if (event == 1) {
                    state.questionsList = [];
                    state.questionsArray = [];
                    setStorage('questionsList',[]) ;
                }else{
                    state.watchTitle = '';
                    state.titleName = '修改'
                }
            },                 // 确认
      cancellation() {
        console.log(state.questionsRow);
        if (state.watchTitle == '编辑') {
            let params = {
                examinationPaperId:state.examinationPaperId,
                oldQuestionsId:state.oldId,
                newQuestionsId:state.questionsRow.oldId,
                isOrder:state.questionsIsoder
            }
            f_update_replaceQuestions(params).then(res=>{
                if (res.data.code == 1) {
                    const item = res.data.data;
                    if (item.topicType == 1) {
                state.danxuanList[state.questionsIndex].id = item.id;
                state.danxuanList[state.questionsIndex].testQuestionId = item.testQuestionId;
                state.danxuanList[state.questionsIndex].testQuestionContent = item.testQuestionContent;
                state.danxuanList[state.questionsIndex].answers = item.answers;
                state.danxuanList[state.questionsIndex].testQuestionAnalysis = item.testQuestionAnalysis;
                state.danxuanList.forEach(element => {
                    element.answers = element.answers.sort((a,b) => a.isOrder - b.isOrder)
                });
            }else if(item.topicType == 2){
                state.duoxuanList[state.questionsIndex].id = item.id;
                state.duoxuanList[state.questionsIndex].testQuestionId = item.testQuestionId;
                state.duoxuanList[state.questionsIndex].testQuestionContent = item.testQuestionContent;
                state.duoxuanList[state.questionsIndex].answers = item.answers;
                state.duoxuanList[state.questionsIndex].testQuestionAnalysis = item.testQuestionAnalysis;
                state.duoxuanList.forEach(element => {
                    element.answers = element.answers.sort((a,b) => a.isOrder - b.isOrder)
                });
            }else if(item.topicType == 3){
                state.tiankongList[state.questionsIndex].id = item.id;
                state.tiankongList[state.questionsIndex].testQuestionId = item.testQuestionId;
                state.tiankongList[state.questionsIndex].testQuestionContent = item.testQuestionContent;
                state.tiankongList[state.questionsIndex].answers = item.answers;
                state.tiankongList[state.questionsIndex].testQuestionAnalysis = item.testQuestionAnalysis;
                state.tiankongList.forEach(element => {
                    element.answers = element.answers.sort((a,b) => a.isOrder - b.isOrder)
                });
            }else if(item.topicType == 4){
                state.panduanList[state.questionsIndex].id = item.id;
                state.panduanList[state.questionsIndex].testQuestionId = item.testQuestionId;
                state.panduanList[state.questionsIndex].testQuestionContent = item.testQuestionContent;
                state.panduanList[state.questionsIndex].answers = item.answers;
                state.panduanList[state.questionsIndex].testQuestionAnalysis = item.testQuestionAnalysis;
                state.panduanList.forEach(element => {
                    element.answers = element.answers.sort((a,b) => a.isOrder - b.isOrder)
                });
            }
                }else{
                    if (state.questionsRow.topicType == 1) {
                state.danxuanList[state.questionsIndex].id = state.questionsRow.newId;
                state.danxuanList[state.questionsIndex].testQuestionId = state.questionsRow.oldId;
                state.danxuanList[state.questionsIndex].testQuestionContent = state.questionsRow.testQuestionContent;
                state.danxuanList[state.questionsIndex].answers = state.questionsRow.answers;
                state.danxuanList[state.questionsIndex].testQuestionAnalysis = state.questionsRow.testQuestionAnalysis;
                state.danxuanList.forEach(element => {
                    element.answers = element.answers.sort((a,b) => a.isOrder - b.isOrder)
                });
            }else if(state.questionsRow.topicType == 2){
                state.duoxuanList[state.questionsIndex].id = state.questionsRow.newId;
                state.duoxuanList[state.questionsIndex].testQuestionId = state.questionsRow.oldId;
                state.duoxuanList[state.questionsIndex].testQuestionContent = state.questionsRow.testQuestionContent;
                state.duoxuanList[state.questionsIndex].answers = state.questionsRow.answers;
                state.duoxuanList[state.questionsIndex].testQuestionAnalysis = state.questionsRow.testQuestionAnalysis;
                state.duoxuanList.forEach(element => {
                    element.answers = element.answers.sort((a,b) => a.isOrder - b.isOrder)
                });
            }else if(state.questionsRow.topicType == 3){
                state.tiankongList[state.questionsIndex].id = state.questionsRow.newId;
                state.tiankongList[state.questionsIndex].testQuestionId = state.questionsRow.oldId;
                state.tiankongList[state.questionsIndex].testQuestionContent = state.questionsRow.testQuestionContent;
                state.tiankongList[state.questionsIndex].answers = state.questionsRow.answers;
                state.tiankongList[state.questionsIndex].testQuestionAnalysis = state.questionsRow.testQuestionAnalysis;
                state.tiankongList.forEach(element => {
                    element.answers = element.answers.sort((a,b) => a.isOrder - b.isOrder)
                });
            }else if(state.questionsRow.topicType == 4){
                state.panduanList[state.questionsIndex].id = state.questionsRow.newId;
                state.panduanList[state.questionsIndex].testQuestionId = state.questionsRow.oldId;
                state.panduanList[state.questionsIndex].testQuestionContent = state.questionsRow.testQuestionContent;
                state.panduanList[state.questionsIndex].answers = state.questionsRow.answers;
                state.panduanList[state.questionsIndex].testQuestionAnalysis = state.questionsRow.testQuestionAnalysis;
                state.panduanList.forEach(element => {
                    element.answers = element.answers.sort((a,b) => a.isOrder - b.isOrder)
                });
            }
                }
                state.dialogVisibletihuan = false;
            })
        }else{
            let obj = {};
        if (Object.keys(state.questionsRow).length>0) {
          if (state.questionsRow.topicType == 1) {
                state.danxuanList[state.questionsIndex].id = state.questionsRow.newId;
                state.danxuanList[state.questionsIndex].testQuestionId = state.questionsRow.oldId;
                state.danxuanList[state.questionsIndex].testQuestionContent = state.questionsRow.testQuestionContent;
                state.danxuanList[state.questionsIndex].answers = state.questionsRow.answers;
                state.danxuanList[state.questionsIndex].testQuestionAnalysis = state.questionsRow.testQuestionAnalysis;
                state.danxuanList.forEach(element => {
                    element.answers = element.answers.sort((a,b) => a.isOrder - b.isOrder)
                });
            }else if(state.questionsRow.topicType == 2){
                state.duoxuanList[state.questionsIndex].id = state.questionsRow.newId;
                state.duoxuanList[state.questionsIndex].testQuestionId = state.questionsRow.oldId;
                state.duoxuanList[state.questionsIndex].testQuestionContent = state.questionsRow.testQuestionContent;
                state.duoxuanList[state.questionsIndex].answers = state.questionsRow.answers;
                state.duoxuanList[state.questionsIndex].testQuestionAnalysis = state.questionsRow.testQuestionAnalysis;
                state.duoxuanList.forEach(element => {
                    element.answers = element.answers.sort((a,b) => a.isOrder - b.isOrder)
                });
            }else if(state.questionsRow.topicType == 3){
                state.tiankongList[state.questionsIndex].id = state.questionsRow.newId;
                state.tiankongList[state.questionsIndex].testQuestionId = state.questionsRow.oldId;
                state.tiankongList[state.questionsIndex].testQuestionContent = state.questionsRow.testQuestionContent;
                state.tiankongList[state.questionsIndex].answers = state.questionsRow.answers;
                state.tiankongList[state.questionsIndex].testQuestionAnalysis = state.questionsRow.testQuestionAnalysis;
                state.tiankongList.forEach(element => {
                    element.answers = element.answers.sort((a,b) => a.isOrder - b.isOrder)
                });
            }else if(state.questionsRow.topicType == 4){
                state.panduanList[state.questionsIndex].id = state.questionsRow.newId;
                state.panduanList[state.questionsIndex].testQuestionId = state.questionsRow.oldId;
                state.panduanList[state.questionsIndex].testQuestionContent = state.questionsRow.testQuestionContent;
                state.panduanList[state.questionsIndex].answers = state.questionsRow.answers;
                state.panduanList[state.questionsIndex].testQuestionAnalysis = state.questionsRow.testQuestionAnalysis;
                state.panduanList.forEach(element => {
                    element.answers = element.answers.sort((a,b) => a.isOrder - b.isOrder)
                });
            }
        }else{
            console.log(1111);
        }
        state.dialogVisibletihuan = false;
        }
       

      },
                            // 操作选择
                            deselect(row){
                                state.tableData.forEach(element => {
                    element.select = 0;
                });
                row.select = 1;
                state.questionsRow = row;
            },
            handleClosetihuan(){
                state.dialogVisibletihuan = false;
            },
                        // 单选替换试题
        duanxuanQuestions(item,index){
            console.log(item,index);
            state.dialogVisibletihuan = true;
            state.topicType = item.topicType;
            state.testQuestionId = item.testQuestionId;
             state.oldId = item.id;
            state.questionsIsoder = index+1;
            state.questionsIndex = index;
            methods.get_select_courseChapterList2(state.courseId);
        },
        // 多选替换试题
        duoxuanQuestions(item,index){
            state.dialogVisibletihuan = true;
            state.topicType = item.topicType;
            state.testQuestionId = item.testQuestionId;
             state.oldId = item.id;
            state.questionsIsoder = index+1;
            state.questionsIndex = index;
            methods.get_select_courseChapterList2(state.courseId);
        },
        // 填空替换试题
        tiankongQuestions(item,index){
            state.dialogVisibletihuan = true;
            state.topicType = item.topicType;
            state.testQuestionId = item.testQuestionId;
             state.oldId = item.id;
            state.questionsIsoder = index+1;
            state.questionsIndex = index;
            methods.get_select_courseChapterList2(state.courseId);
        },
        // 判断替换试题
        panduanQuestions(item,index){
            state.dialogVisibletihuan = true;
            state.topicType = item.topicType;
            state.testQuestionId = item.testQuestionId;
             state.oldId = item.id;
            state.questionsIsoder = index+1;
            state.questionsIndex = index;
            methods.get_select_courseChapterList2(state.courseId);
        },
                     // 章的监听事件
                     zhangChange2(index) {
                state.jieValue = "";
                    // state.ruleForm.domains2[index].jieId = "";
                    methods.get_select_courseSectionList2(state.zhangValue);
                
                },
                  // 获取章下拉列表
          get_select_courseChapterList2(courseName) {
                let params = {
                courseId: courseName,
                };
                get_select_courseChapterList(params).then((res) => {
                if (res.data.code == 1) {
                    state.zhangOptions = res.data.data;
                    if (state.zhangOptions.length >0) {
                        state.zhangValue = state.zhangOptions[0].id;
                        methods.get_select_courseSectionList2(state.zhangValue);
                    }
                    
                    
                }
                });
            },
                get_select_courseSectionList2(zhangId) {
                    let params = {
                    courseChapterId: zhangId,
                    };
                    get_select_courseSectionList(params).then((res) => {
                    if (res.data.code == 1) {
                        state.jieOptions = res.data.data;
                        if (state.jieOptions.length>0) {
                            state.jieValue = state.jieOptions[0].id;
                        }
                        
                        methods.get_select_questionList();
                    }
                    });
                },
                              // 获取试题列表
              get_select_questionList(){
            let params = {
                courseId:state.courseId,
                courseChapterId:state.zhangValue,
                courseSectionId:state.jieValue,
                questionBankType:1,
                topicDifficulty:state.nanduValue,
                pageIndex:state.currentPageList,
                pageSize:5,
                content:state.stemData,
                topicType:state.topicType,
                userName:state.upDataName,
            }
            get_select_questionList(params).then(res=>{
                if (res.data.code == 1) {
                    state.tableData = res.data.data.list;
                    state.total = res.data.data.totalCount.Value;
                    if (state.tableData.length>0) {
                        state.tableData.forEach(element => {
                        element.answers.forEach(item => {
                            if (item.isAnswer) {
                                element.correctAnswer = item.content;
                            }
                        });
                    });
                    }
                    
                }
            })
        },
        onSubmit(){
            state.currentPageList = 1;
            methods.get_select_questionList();
        },
        handleCurrentChangelist(event){
            state.currentPageList = event;
            methods.get_select_questionList();
        },
                        // 试卷预览
                        testPaper(){
                           
                const allValuesNotEmpty = state.form.domains.every((element) => {
                    return Object.values(element).every((value) => value !== "");
                    });
                    if (allValuesNotEmpty) {
                        if (state.watchTitle == '编辑') {
                            state.examPreview = true;
                        }else{
                            let arraylist = [];
                             state.form.domains.forEach((element) => {
                            arraylist.push({
                                courseId: state.courseId,
                                courseChapterId: element.zhangValue,
                                courseSectionId: element.jieValue,
                                topicType: element.tixingValue,
                                topicDifficulty: element.nanduValue,
                                topicCount: element.stNum,
                            });
                            });
                        
                       
                        f_intsert_generatePreviewPapers(arraylist).then(res=>{
                            if (res.data.code == 1) {
                                if (state.titleName == '修改') {
                                state.questionsArray = res.data.data;
                                state.questionsList = [];
                                state.questionsArray.forEach((element) => {
                                    element.questions.forEach((item) => {
                                    state.questionsList.push(item);
                                    });
                                });
                                state.danxuanList = [];
                               state.duoxuanList = [];
                               state.tiankongList = [];
                               state.panduanList = [];
                               state.questionsList.forEach(element => {
                                       if (element.topicType == 1) {
                                       state.danxuanList.push(element);
                                       console.log(state.danxuanList);
                                   }else if(element.topicType == 2){
                                       state.duoxuanList.push(element);
                                   }else if(element.topicType == 3){
                                       state.tiankongList.push(element)
                                   }else{
                                       state.panduanList.push(element)
                                       console.log(state.panduanList);
                                   }
                                   
                                   
                               });
                            }
                                
                                setStorage("questionsList", state.questionsList);
                                state.examPreview = true;
                                // router.push({path:'/home/testPaperPreview',query:{jieName:state.form.jieName,exercisesnName:state.form.exercisesnName}});
                            }
                        })
                        }

                    }else{
                        ElMessage({
                            message: "请完善考试信息",
                            type: "info",
                            showClose: true,
                        });
                        state.radio1 = "1";
                    }
                
                 
            },
            f_intsert_generatePreviewPapers(){
                const allValuesNotEmpty = state.form.domains.every((element) => {
                    return Object.values(element).every((value) => value !== "");
                    });
                    if (allValuesNotEmpty) {
                        let arraylist = [];
                             state.form.domains.forEach((element) => {
                            arraylist.push({
                                courseId: state.courseId,
                                courseChapterId: element.zhangValue,
                                courseSectionId: element.jieValue,
                                topicType: element.tixingValue,
                                topicDifficulty: element.nanduValue,
                                topicCount: element.stNum,
                            });
                            });
                        
                       
                        f_intsert_generatePreviewPapers(arraylist).then(res=>{
                            if (res.data.code == 1) {
                                if (state.titleName == '修改') {
                                state.questionsArray = res.data.data;
                                state.questionsList = [];
                                state.questionsArray.forEach((element) => {
                                    element.questions.forEach((item) => {
                                    state.questionsList.push(item);
                                    });
                                });
                                state.danxuanList = [];
                               state.duoxuanList = [];
                               state.tiankongList = [];
                               state.panduanList = [];
                               state.questionsList.forEach(element => {
                                       if (element.topicType == 1) {
                                       state.danxuanList.push(element);
                                       console.log(state.danxuanList);
                                   }else if(element.topicType == 2){
                                       state.duoxuanList.push(element);
                                   }else if(element.topicType == 3){
                                       state.tiankongList.push(element)
                                   }else{
                                       state.panduanList.push(element)
                                       console.log(state.panduanList);
                                   }
                                   
                                   
                               });
                            }
                                
                                setStorage("questionsList", state.questionsList);
                               
                                // router.push({path:'/home/testPaperPreview',query:{jieName:state.form.jieName,exercisesnName:state.form.exercisesnName}});
                            }
                        })
                    }else{
                        ElMessage({
                            message: "请完善信息",
                            type: "info",
                            showClose: true,
                        });
                        state.radio1 = "1";
                    }
            },
            examPreviewClose(){
                state.examPreview = false;
            },
            // 获取章下拉列表
            get_select_courseChapterList() {
                    let params = {
                    courseId: state.courseId,
                    };
                    console.log(params);
                    get_select_courseChapterList(params).then((res) => {
                    if (res.data.code == 1) {
                        state.zhangOptions = res.data.data;
                    }
                    });
                },
                                 // 章的监听事件
                                 zhangChange(index) {
                        state.form.domains[index].jieValue = "";
                        methods.get_select_courseSectionList(
                        state.form.domains[index].zhangValue
                        );
                        methods.get_topicCount(
                            state.form.domains[index].tixingValue,
                            state.form.domains[index].nanduValue,
                            state.courseId,
                            state.form.domains[index].zhangValue,
                            state.form.domains[index].jieValue,
                            index
                        );
                        state.titleName = '修改';
                        state.watchTitle = '';
                    },
                    visibleChange(index){
                        methods.get_select_courseSectionList(
                        state.form.domains[index].zhangValue
                        );
                    },
                      // 获取节下拉数据
                get_select_courseSectionList(zhangId) {
                    let params = {
                    courseChapterId: zhangId,
                    };
                    get_select_courseSectionList(params).then((res) => {
                    if (res.data.code == 1) {
                        state.jieOptions = res.data.data;
                    }
                    });
                },
                                // 节的监听事件
                                jieChange(index){
                    methods.get_topicCount(
                            state.form.domains[index].tixingValue,
                            state.form.domains[index].nanduValue,
                            state.courseId,
                            state.form.domains[index].zhangValue,
                            state.form.domains[index].jieValue,
                            index
                        );
                        state.titleName = '修改';
                        state.watchTitle = '';
                },
                // 题型监听事件
                tixingChange(index){
                    methods.get_topicCount(
                            state.form.domains[index].tixingValue,
                            state.form.domains[index].nanduValue,
                            state.courseId,
                            state.form.domains[index].zhangValue,
                            state.form.domains[index].jieValue,
                            index
                        );
                        state.titleName = '修改';
                        state.watchTitle = '';
                },
                // 难度监听事件
                nanduChange(index){
                    methods.get_topicCount(
                            state.form.domains[index].tixingValue,
                            state.form.domains[index].nanduValue,
                            state.courseId,
                            state.form.domains[index].zhangValue,
                            state.form.domains[index].jieValue,
                            index
                        );
                        state.titleName = '修改';
                        state.watchTitle = '';
                },
                // 试题数量监听
                stNumBlur(){
                    state.titleName = '修改';
                    state.watchTitle = '';
                },
                                  // 获取当前符合条件的试题数
                                  get_topicCount(
                        topicType,
                        topicDifficulty,
                        courseId,
                        courseChapterId,
                        courseSectionId,
                        index
                    ) {
                        if (
                        topicType &&
                        topicDifficulty &&
                        courseId &&
                        courseChapterId &&
                        courseSectionId
                        ) {
                        let params = {
                            topicType: topicType,
                            topicDifficulty: topicDifficulty,
                            questionBankType: 1,
                            courseId: courseId,
                            courseChapterId: courseChapterId,
                            courseSectionId: courseSectionId,
                        };
                        get_topicCount(params).then((res) => {
                            if (res.data.code == 1) {
                            state.form.domains[index].Eligible = res.data.data;
                            }
                        });
                        }
                    },
            deleteClickData(index){
                if (state.form.domains.length == 1) {
                    return
                }
                state.form.domains.splice(index,1);
                state.titleName = '修改';
            },
            // 配置习题
            dialogVisibleClick(row){
                state.dialogVisible = true;
                state.jieName = row.catalogueName;
                state.jieId = row.id;
                methods.get_select_courseChapterList();
                if (route.query.itemRow) {
                    methods.f_get_courseExercises();
                }
            },
            changeDialog(){
                state.dialogVisible = false;
                state.form = {
                exercisesnName:'',
                exercisesnDate:[],
                domains:[
                    {
                        zhangValue:'',
                        jieValue:'',
                        tixingValue:'',
                        nanduValue:'',
                        Eligible:0,
                        stNum:'',

                    }
                ],
            };
            },
            teacherAdd() {
                let params = [];
                params.push({
                    courseId: getStorage('courseId'),
                    teacherName: state.teacherhead,
                    teacherPicId: state.teacherimgUrlId.toString(),
                    teacherType: 1,
                    isOrder: 0
                })
                if (state.techerUploadArray.length > 0) {
                    state.techerUploadArray.forEach((element, index) => {
                        console.log(element);
                        params.push({
                            courseId: getStorage('courseId'),
                            teacherName: element.teacherName,
                            teacherPicId: element.teacherimgUrlId,
                            teacherType: 2,
                            isOrder: index + 1
                        })
                    });
                }
                console.log(params);
                f_update_courseTeacherTeam(params).then(res => {
                    if (res.data.code == 1) {
                        ElMessage({
                            type: 'success',
                            message: '上传成功',
                        })
                    }
                })
            },
            // 上传教师团队
            techerSuccessChange(obj, res, file) {
                console.log(obj, res, file);
                if (state.techerUploadArray[obj.index].imageUrl && state.techerUploadArray[obj.index].imageUrl != res.data[0].filePath) {
                    DeleteFiles(state.techerUploadArray[obj.index].teacherimgUrlId.split()).then(res => {
                        if (res.data.code == 1) {
                        }
                    })
                }
                state.techerUploadArray[obj.index].imageUrl = state.baseUrl + '/' + res.data[0].filePath;
                state.techerUploadArray[obj.index].teacherimgUrlId = res.data[0].Id;
            },
            // 删除教师位置
            deleteImgClick(index) {
                DeleteFiles(state.techerUploadArray[index].teacherimgUrlId.split()).then(res => {
                    if (res.data.code == 1) {
                    }
                })
                state.techerUploadArray.splice(index, 1)
            },
            // 增加教师位置
            addTeacher() {
                state.techerUploadArray.push(
                    {
                        imageUrl: '',
                        teacherimgUrlId: '',
                        teacherName: '',
                    }
                )
            },
            // 获取教师数据
            f_get_courseTeacherTeamList() {
                let params = {
                    courseId: getStorage('courseId'),
                }
                f_get_courseTeacherTeamList(params).then(res => {
                    if (res.data.code == 1) {
                        state.techerUploadArray = [];
                        res.data.data.forEach(element => {
                            if (element.teacherType == 1) {
                                state.imageUrl = element.teacherPic;
                                state.baseUrlimageUrl = state.baseUrl + '/' + element.teacherPic;
                                state.teacherhead = element.teacherName;
                                state.teacherimgUrlId.push(element.id);
                            } else {

                                state.techerUploadArray.push({
                                    imageUrl: state.baseUrl + '/' + element.teacherPic,
                                    teacherimgUrlId: element.id,
                                    teacherName: element.teacherName
                                })
                            }
                        });
                        console.log(state.techerUploadArray.push);
                    }
                })
            },
            // 确认节
            wanchengjoint() {
                ruleFormRefjoint.value.validate((valid) => {
                    if (valid) {
                        let params = {};
                        if (state.titlejoint == '添加节') {
                            params = {
                                courseId: getStorage('courseId'),
                                catalogueName: state.ruleFormjoint.zhangname,
                                isOrder: state.ruleFormjoint.sortNum,
                                parentId: state.parentId
                            }
                        }
                        else {
                            params = {
                                courseId: getStorage('courseId'),
                                catalogueName: state.ruleFormjoint.zhangname,
                                isOrder: state.ruleFormjoint.sortNum,
                                id: state.rowJointId,
                                parentId: state.parentId
                            }
                        }

                        f_update_courseSection(params).then(res => {
                            if (res.data.code == 1) {
                                ElMessage({
                                    showClose: true,
                                    message: res.data.message,
                                    type: "success",
                                });
                                methods.quxiaojoint();
                                methods.f_get_courseCatalogueJoint();
                            }
                        })
                    } else {
                        console.log("未通过");
                    }
                })
            },
            // 节数据列表
            f_get_courseCatalogueJoint() {
                let params = {
                    id: state.parentId,
                    catalogueType: 2,
                    pageIndex: state.currentPagejonit,
                    pageSize: state.pageSizejoint
                }
                f_get_courseCatalogue(params).then(res => {
                    if (res.data.code == 1) {
                        state.tableDataJoint = res.data.data.list;
                        console.log(state.tableDataJoint, '节数据列表');
                        state.totaljoint = res.data.data.totalCount.Value;
                    }
                })
            },
            //节分页
            handleCurrentjointChange: (val) => {
                state.currentPagejonit = val;
                methods.f_get_courseCatalogueJoint()
            },
            handleSizejointChange: (val) => {
                state.pageSizejoint = val;
                methods.f_get_courseCatalogueJoint()
            },
            // 添加节
            addjoint() {
                state.addjointShow = true;
                state.titlejoint = '添加节';
            },
            // 取消节
            quxiaojoint() {
                state.addjointShow = false;
                state.ruleFormjoint = {};
            },
            // 返回章
            blackZhang() {
                state.chapterShow = true;
                methods.f_get_courseCatalogue()
            },
            // 设置节
            setupJoint(row) {
                state.chapterShow = false;
                state.parentId = row.id;
                state.zhangName = row.catalogueName;
                methods.f_get_courseCatalogueJoint();
            },
            // 编辑节
            editClickJoint(row) {
                state.addjointShow = true;
                state.titlejoint = '编辑节';
                state.ruleFormjoint.zhangname = row.catalogueName;
                state.ruleFormjoint.sortNum = row.isOrder;
                state.rowJointId = row.id;
            },
            // 删除节
            deleteClickJoint(row) {
                ElMessageBox.confirm(
                    '确认要删除此数据吗',
                    '删除',
                    {
                        confirmButtonText: '确认',
                        cancelButtonText: '取消',
                        type: 'warning',
                    }).then(() => {

                        f_delete_courseCatalogue(row.id).then(res => {
                            if (res.data.code == 1) {
                                ElMessage({
                                    type: 'success',
                                    message: '删除成功',
                                })
                                methods.f_get_courseCatalogueJoint()
                            }
                        })


                    })
                    .catch(() => {
                        ElMessage({
                            type: 'info',
                            message: '取消删除',
                        })
                    })
            },
            // 编辑章
            editClick(row) {
                state.addZhang = true;
                state.titleZhang = '编辑章';
                state.zhangid = row.id;
                state.ruleForm.zhangname = row.catalogueName;
                state.ruleForm.sortNum = row.isOrder;

            },
            // 删除章
            deleteClick(row) {
                ElMessageBox.confirm(
                    '确认要删除此数据吗',
                    '删除',
                    {
                        confirmButtonText: '确认',
                        cancelButtonText: '取消',
                        type: 'warning',
                    }).then(() => {

                        f_delete_courseCatalogue(row.id).then(res => {
                            if (res.data.code == 1) {
                                ElMessage({
                                    type: 'success',
                                    message: '删除成功',
                                })
                                methods.f_get_courseCatalogue()
                            }else{
                                ElMessage({
                                    type: 'error',
                                    message: res.data.message,
                                })
                            }
                        })


                    })
                    .catch(() => {
                        ElMessage({
                            type: 'info',
                            message: '取消删除',
                        })
                    })

            },
            // 获取章的列表数据
            f_get_courseCatalogue() {
                let params = {
                    id: getStorage('courseId'),
                    catalogueType: 1,
                    pageIndex: state.currentPageZhiang,
                    pageSize: state.pageSizeZhiang
                }
                f_get_courseCatalogue(params).then(res => {
                    if (res.data.code == 1) {
                        state.tableDatazhang = res.data.data.list;
                        console.log(state.tableDatazhang, '章的列表数据');
                        state.totalZhang = res.data.data.totalCount.Value;
                    }
                })
            },
            //章分页
            handleCurrentChange: (val) => {
                state.currentPageZhiang = val;
                methods.f_get_courseCatalogue()
            },
            handleSizeChange: (val) => {
                state.pageSizeZhiang = val;
                methods.f_get_courseCatalogue()
            },
            // 添加章
            addchapter() {
                state.addZhang = true;
                state.titleZhang = '添加章'
            },
            // 取消章
            quxiao() {
                state.addZhang = false;
                state.ruleForm = {}
            },
            // 完成章
            wancheng() {
                ruleFormRef.value.validate((valid) => {
                    if (valid) {
                        let params = {};
                        if (state.titleZhang == '添加章') {
                            params = {
                                courseId: getStorage('courseId'),
                                catalogueName: state.ruleForm.zhangname,
                                isOrder: state.ruleForm.sortNum
                            }
                        } else {
                            params = {
                                courseId: getStorage('courseId'),
                                catalogueName: state.ruleForm.zhangname,
                                isOrder: state.ruleForm.sortNum,
                                id: state.zhangid
                            }
                        }

                        f_update_courseChapter(params).then(res => {
                            if (res.data.code == 1) {
                                ElMessage({
                                    showClose: true,
                                    message: res.data.message,
                                    type: "success",
                                });
                                methods.quxiao();
                                methods.f_get_courseCatalogue();
                            }
                        })
                    } else {
                        console.log("未通过");
                    }
                })
            },
            // 完成（基本信息）
            completeClick() {
                if (!state.coverPictureId || !state.courseName || !state.courDate || !state.productionUnit || !state.category || !state.majorValue || !state.subjectValue || !state.producer || !state.pursuant || !state.courseDetails) {
                    return ElMessage({
                        showClose: true,
                        message: '请填写完整基本信息',
                        type: "warning",
                    });
                }
                let params = {};
                if (state.courseId) {
                     params = {
                    coverPictureId: state.coverPictureId,
                    courseName: state.courseName,
                    publishTime: state.courDate,
                    productionUnit: state.productionUnit,
                    courseType: state.category,
                    majorId: state.majorValue,
                    subjectId: state.subjectValue,
                    producer: state.producer,
                    pursuant: state.pursuant,
                    courseDetails: state.courseDetails,
                    status: 2,
                    id:state.courseId,
                    userId: getStorage('userId')
                }
                }else{
                    params = {
                    coverPictureId: state.coverPictureId,
                    courseName: state.courseName,
                    publishTime: state.courDate,
                    productionUnit: state.productionUnit,
                    courseType: state.category,
                    majorId: state.majorValue,
                    subjectId: state.subjectValue,
                    producer: state.producer,
                    pursuant: state.pursuant,
                    courseDetails: state.courseDetails,
                    status: 2,
                    userId: getStorage('userId')
                }
                }
                
                console.log(params);
                f_update_course(params).then(res => {
                    if (res.data.code == 1) {
                        state.courseId = res.data.data;
                        setStorage('courseId', state.courseId);
                        ElMessage({
                            showClose: true,
                            message: res.data.message,
                            type: "success",
                        });
                        state.tabIndex = 1;
                        methods.f_get_courseCatalogue();
                    } else {
                        ElMessage({
                            showClose: true,
                            message: res.data.message,
                            type: "error",
                        });
                    }
                })
            },
            // 获取专业下拉
            get_select_majorList() {
                let params = {
                    departmentId: ''
                }
                get_select_majorList(params).then(res => {
                    if (res.data.code == 1) {
                        state.majorOptions = res.data.data;
                    }
                })
            },
            majorChange(event) {
                let params = {
                    majorId: event
                }
                get_select_subjectList(params).then(res => {
                    if (res.data.code == 1) {
                        state.subjectOptions = res.data.data;
                    }
                })
            },
            get_select_subjectList() {
                let params = {
                    majorId: ''
                }
                get_select_subjectList(params).then(res => {
                    if (res.data.code == 1) {
                        state.subjectOptions = res.data.data;
                    }
                })
            },
            techerSuccess(res) {
                // console.log(res);
                if (state.imageUrl && state.imageUrl != res.data[0].filePath) {
                    DeleteFiles(state.teacherimgUrlId).then(res => {
                        if (res.data.code == 1) {
                            // state.teacherimgUrlId = [];
                            // state.imageUrl = '';
                        }
                    })
                }
                state.teacherimgUrlId = [];
                state.teacherimgUrlId.push(res.data[0].Id);
                state.imageUrl = res.data[0].filePath;
                console.log(state.teacherimgUrlId);
                state.baseUrlimageUrl = state.baseUrl + '/' + state.imageUrl
            },
            techerSuccessExceed() {

            },
            handleAvatarSuccess(res) {
                console.log(res);
                state.imgUrlId = [];
                state.imgUrl = res.data[0].filePath;
                state.imgUrlId.push(res.data[0].Id);
                state.coverPictureId = res.data[0].Id;
            },
            deleteClickImg() {
                DeleteFiles(state.imgUrlId).then(res => {
                    if (res.data.code == 1) {
                        state.imgUrlId = [];
                        state.imgUrl = '',
                            state.coverPictureId = '';
                    }
                })

            },
            // 获取课程下的课程思政
            f_get_courseIdeologyPolitics(){
                let params = {
                    courseId:getStorage('courseId'),
                }
                f_get_courseIdeologyPolitics(params).then(res=>{
                    if (res.data.code == 1) {
                        state.educationForm.caseSharingId = res.data.data.caseSharingId;
                        methods.csColumn(state.educationForm.caseSharingId);
                        methods.cscraftsman(state.educationForm.caseSharingId);
                        methods.csmajorroom(state.educationForm.caseSharingId);
                        state.cscolumsdata = res.data.data.csColumnsIds.split(",");
                        state.educationForm.csColumnsIds = res.data.data.csColumnsIds.split(",");
                        state.educationForm.id = res.data.data.id;
                        state.isShowcsMajorClassRoomdetermine = res.data.data.isShowcsMajorClassRoom?1:0;
                        state.educationForm.csMajorClassRoomIds = res.data.data.csMajorClassRoomIds.split(",");
                        state.isShowcsGreatCountryCraftsmandetermine = res.data.data.isShowcsGreatCountryCraftsman?1:0;
                        state.educationForm.csGreatCountryCraftsmanIds = res.data.data.csGreatCountryCraftsmanIds.split(",");
                    }
                })
            },
            tabClick(index) {
                let courseId = getStorage('courseId');
                if (courseId) {
                    state.tabIndex = index;
                    if (index == 1) {
                        methods.f_get_courseCatalogue();
                    } else if (index == 2) {
                        methods.f_get_courseTeacherTeamList()
                    }else if(index == 3){
                        methods.f_get_courseIdeologyPolitics();
                    }
                } else {
                    ElMessage({
                        showClose: true,
                        message: '请先填写基本信息',
                        type: "warning",
                    });
                }
            },
            cligocenterHUI() {
                router.push('/home/courseList')
            },
            cligocenterCUN() {
                router.push('/home/courseList')
            },
            cligocenterFA() {
                router.push('/home/courseList')
            },

            creatAfterClick() {
                state.UploadDialog3 = true
            },
            creatAfterClickque() {
                state.UploadDialog3 = false
            },
            creatAfterClickqu() {
                state.UploadDialog3 = false
            },
            yulanClick() {
                router.push('/home/testPaperPreview')
            },
            addFrom() {
                state.form.domains.push({
                        zhangValue: "",
                        jieValue: "",
                        tixingValue: "",
                        nanduValue: "",
                        stNum: "",
                        Eligible:0
                    },)
            },
            delete2Click(index) {
                state.domains2.splice(index, 1)
            },
            hanClicktian() {
                state.UploadDialog2 = true
            },
            hanClicktianque() {
                state.UploadDialog2 = false
            },
            hanClicktianqu() {
                state.UploadDialog2 = false
            },

            //添加课程内容
            distrClick(row) {
                console.log(row.courseContentFile);
                if (row.courseContentFile.filePath) {
                    state.tableData1 = [
                            {
                                name: '',
                                fileId: '',
                            }
                        ];
                let catalogueItem = state.tableDataJoint.find((item)=>{
                    return item.id == row.id
                })
                let File = catalogueItem.courseContentFile
                console.log(File);
                state.tableData1[0].name = File.fileName;
                state.tableData1[0].fileId = File.Id;
                }else{
                    state.tableData1 = [];
                }
                

                state.UploadDialog5 = true

                state.distrCourseSectionId6 = row.id //节id
                console.log(row.id);
            },
            handleBeforeUpload(file) {
                //获取上传文件大小
                let fileSize = Number(file.size / 1024 / 1024);

                if (fileSize > 100) {
                    ElMessage({ message: '文件大小不能超过100MB，请重新上传。', type: 'warning' })
                    return false
                }
            },
            uploadFile(params) {
                if (params.file == null) {
                    ElMessage({ message: '请选择需要上传的文件', type: 'warning' })
                    return false
                }
            },
            handleRemove(file, fileList) {
                state.fileLists = fileList
            },
            handleFileChange(file, fileList) {
                // state.fileLists = fileList
            },
            handleExceed(files, fileList) {
                ElMessage({ message: '最多上传3个文件，请删除后重新上传！', type: 'warning' })
            },
            handleFileSuccess(response,file,fileList){
                state.fileLists = response.data;
                console.log(response,'response');
                console.log(file,'file')
                console.log(fileList,'filelist');
                let params = {
                    courseSectionId: state.coursewareId,
                    fileIds:state.fileLists[0].Id
                }
                f_insert_courseWareFile(params).then(res=>{

                })
            },
            filefn() {
                console.log(guid());
            },
            // 图片上传限制
            beforeUpload: (file) => {
                const is1M = file.size / 1024; // 限制小于3M
                if (!is1M) {
                    ElMessage({
                        showClose: true,
                        message: "大小不可超过1G",
                        type: "error",
                    });
                }
                return is1M;
            },
            addvideo(event, id, item) {
                console.log(111);
                let file = event.target.files[0];
                console.log(event, id)
                state.input1 = file.name;

                state.nums = id;
                const is1M = file.size / 1024 / 1024 / 1024; // 限制小于3M
                console.log(event, id, is1M)
                if (is1M > 1) {

                    ElMessage({
                        showClose: true,
                        message: "大小不可超过1G",
                        type: "error",
                    });
                    return
                }
                state.loading = ElLoading.service({
                        lock: true,
                        text: 'Loading',
                        background: 'rgba(0, 0, 0, 0.7)',
                    })
                methods.UploadFile(file, id, item);
                event.target.value = null
                console.log(event, file, file.name, file.files);
            },
            // 图片上传成功函数
            successfn: (response) => { },
            // 分片上传文件
            UploadFile(targetFile, id, item) {
                // 创建上传文件分片缓冲区
                var fileChunks = [];
                let fileid = guid();
                let Suffix = targetFile.name.substring(targetFile.name.lastIndexOf(".") + 1);

                // 目标文件
                var file = targetFile;
                // 设置分片缓冲区大小
                var maxFileSizeMB = 1;
                var bufferChunkSize = maxFileSizeMB * (1024 * 1024);
                // 读取文件流其实位置
                var fileStreamPos = 0;
                // 设置下一次读取缓冲区初始大小
                var endPos = bufferChunkSize;
                // 文件大小
                var size = file.size;
                // 将文件进行循环分片处理塞入分片数组
                let das = [];
                while (fileStreamPos < size) {
                    var fileChunkInfo = {
                        file: file.slice(fileStreamPos, endPos),
                        start: fileStreamPos,
                        end: endPos,
                    };
                    fileChunks.push(fileChunkInfo);
                    fileStreamPos = endPos;
                    endPos = fileStreamPos + bufferChunkSize;
                }
                // 获取上传文件分片总数量

                var totalParts = fileChunks.length;

                console.log(totalParts);
                var partCount = 0;
                // 循环调用上传每一片
                let chunk = null;
                while ((chunk = fileChunks.shift())) {
                    partCount++;
                    // 上传文件命名约定
                    var filePartName = file.name + ".partNumber-" + partCount;
                    chunk.filePartName = filePartName;
                    var url =
                        "Id=" +
                        fileid +
                        "&FileType=5" +
                        "&FileName=" +
                        file.name +
                        "&Suffix=." +
                        Suffix +
                        "&PartNumber=" +
                        partCount +
                        "&Chunks=" +
                        totalParts +
                        "&Size=" +
                        bufferChunkSize;

                    chunk.urlParameter = url;
                    // 上传文件
                    methods.UploadFileChunk(chunk, totalParts, partCount, id, size, das, item);
                }
            },

            UploadFileChunk(chunk, totalParts, partCount, id, size, das, item) {
                var data = new FormData();
                data.append("file", chunk.file, chunk.filePartName);
                axios({
                    url:
                        baseUrl +
                        "/Training_API/api/UploadFile/SectionUpload?" +
                        chunk.urlParameter,
                    method: "post",
                    cache: false,
                    contentType: false,
                    processData: false,
                    data: data,
                }).then((res) => {
                    das.push(partCount);
                    state.percent = (das.length / totalParts) * 100;

                    state.progressFlag = true;
                   

                    let resData = res.data;
                    let { code, data, message } = { ...resData };
                    if (totalParts == partCount && code == 1) {
                        setTimeout(() => {
                            state.progressFlag = false;
                            state.loading.close()
                            state.percent = 0;
                        }, 1000);
                        let fileData = data[0];
                        console.log(fileData, 'state.tableData1[0]');
                        state.tableData1 = [
                            {
                                name: '',
                                fileId: '',
                            }
                        ];
                        state.tableData1[0].name = fileData.fileName;
                        state.tableData1[0].fileId = fileData.Id;
                        // item.fileId = state.videourls.Id;
                        let filetype = null;
                        setTimeout(() => {
                            ElMessage({
                            showClose: true,
                            message: message,
                            type: "success",
                        });
                        },1000)
                        
                       
                    }
                });
            },
            //添加课程的重新上传
            uploadingCourse() {
                state.tableData1 = [
                    // {
                    //     name:'',
                    //     fileId:'',
                    // }
                ];
            },
            //点击确定添加课程
            distrClickque() {
                if (!state.tableData1[0].name || !state.tableData1[0].fileId) {
                    ElMessage.error("请上传课程")
                    return
                }
                let datas = {
                    courseSectionId: state.distrCourseSectionId6,
                    fileIds: state.tableData1[0].fileId,
                }
                f_insert_courseContentFile(datas).then((res) => {
                    if (res.data.code == 1) {
                        ElMessage.success(res.data.message)
                        state.UploadDialog5 = false;
                        methods.f_get_courseCatalogueJoint()
                    } else {
                        ElMessage.success(res.data.message)
                    }
                })
            },
            distrClickqu() {
                state.UploadDialog5 = false
            },
            //添加课件
            exerCiseSocreClick(item) {
                console.log(item);
                state.UploadDialog6 = true
                state.coursewareId = item.id;
                if (item.coursewareFiles.length>0) {
                    state.fileLists = item.coursewareFiles;
                }else{
                    state.fileLists = [];
                }
            },
            //删除当前节课件视频
            deletecourseware(row) {
                console.log(row);
                DeleteFiles(row.Id.split(",")).then(res => {
                        if (res.data.code == 1) {
                        }
                    })
                state.fileLists.splice(row,1)
            },
            //添加节的重新上传
            resetcourseware() {
                state.tableData2 = [];
            },
            exerCiseSocreClickque() {
                state.UploadDialog6 = false;
                methods.f_get_courseCatalogueJoint()
            },
            exerCiseSocreClickqu() {
                state.UploadDialog6 = false
            },
            get_select_caseSharing() {
                get_select_caseSharing().then(res => {
                    const { code, data } = res.data
                    if (code == 1) {
                        state.caseShareData = data
                    }
                })
            },
            change(e) {
                methods.csColumn(e);
                methods.cscraftsman(e);
                methods.csmajorroom(e);
            },
            //获取精品案例展示内容
            csColumn(e) {
                let params = {
                    caseSharingId: e
                }
                get_select_csColumn(params).then(res => {
                    const { code, data } = res.data
                    if (code == 1) {
                        state.csColumnData = data
                        console.log(1111);
                    }
                })
            },
            //不展示专业讲堂是清空select
            showcscraft(e) {
                state.isShowcsMajorClassRoomdetermine = e
                if (e == 1) {
                    state.educationForm.csMajorClassRoomIds = []
                }else{
                    state.educationForm.csMajorClassRoomIds = [];
                }
            },
            //专业讲堂
            cscraftsman(e) {
                let params = {
                    caseSharingId: e
                }
                get_select_csGreatCountryCraftsman(params).then(res => {
                    const { code, data } = res.data
                    if (code == 1) {
                        state.cscraftsmanData = data
                    }
                })
            },
            //不展示思政亮点是清空select
            showcraftsman(e) {
                state.isShowcsGreatCountryCraftsmandetermine = e
                if (e == 1) {
                    state.educationForm.csGreatCountryCraftsmanIds = []
                }else{
                    state.educationForm.csGreatCountryCraftsmanIds = [];
                }
            },
            //思政亮点
            csmajorroom(e) {
                let params = {
                    caseSharingId: e
                }
                get_select_csMajorClassRoom(params).then(res => {
                    const { code, data } = res.data
                    if (code == 1) {
                        state.csmajorroomData = data
                    }
                })
            },
            //展示内容的多选
            changecscolums(e, id) {
                if (e) {
                    state.educationForm.csColumnsIds.push(id)
                } else {
                    state.educationForm.csColumnsIds.splice(this.educationForm.csColumnsIds.indexOf(id), 1)
                }
            },
            //新增课程思政
            confirmeducation() {
                if (state.educationForm.caseSharingId == '' || state.educationForm.csColumnsIds.length == 0) {
                    return ElMessage({
                                showClose: true,
                                message: '请填写完整基本信息',
                                type: "warning",
                            });
                }
                if (state.isShowcsGreatCountryCraftsmandetermine == 0) {
                        if (state.educationForm.csGreatCountryCraftsmanIds.length == 0) {
                           return ElMessage({
                                showClose: true,
                                message: '请选择专业讲堂的展示内容',
                                type: "warning",
                            });
                        }
                    }
                    if (state.isShowcsMajorClassRoomdetermine == 0) {
                        if (state.educationForm.csMajorClassRoomIds.length == 0) {
                            return ElMessage({
                                showClose: true,
                                message: '请选择思政亮点的展示内容',
                                type: "warning",
                            });
                        }
                    }
                let params = {};
                if (state.educationForm.id) {
                     params = {
                        caseSharingId:state.educationForm.caseSharingId,
                        courseId:getStorage('courseId'),
                        csColumnsIds:state.educationForm.csColumnsIds.toString(),
                        csGreatCountryCraftsmanIds:state.educationForm.csGreatCountryCraftsmanIds.toString(),
                        csMajorClassRoomIds:state.educationForm.csMajorClassRoomIds.toString(),
                        isShowcsGreatCountryCraftsman:state.isShowcsGreatCountryCraftsmandetermine == 0 ? false : true,
                        isShowcsMajorClassRoom:state.isShowcsMajorClassRoomdetermine == 0 ? false : true,
                        id:state.educationForm.id
                       }
                }else{
                    params = {
                        caseSharingId:state.educationForm.caseSharingId,
                        courseId:getStorage('courseId'),
                        csColumnsIds:state.educationForm.csColumnsIds.toString(),
                        csGreatCountryCraftsmanIds:state.educationForm.csGreatCountryCraftsmanIds.toString(),
                        csMajorClassRoomIds:state.educationForm.csMajorClassRoomIds.toString(),
                        isShowcsGreatCountryCraftsman:state.isShowcsGreatCountryCraftsmandetermine == 0 ? false : true,
                        isShowcsMajorClassRoom:state.isShowcsMajorClassRoomdetermine == 0 ? false : true,
                       }
                }
                       
                           
                            f_update_courseIdeologyPolitics(params).then(res => {
                                console.log(res.data, 'res')
                                if (res.data.code == 1) {
                                    ElMessage({
                                        showClose: true,
                                        message: '成功',
                                        type: "warning",
                                    });
                                    methods.f_get_courseIdeologyPolitics();
                                    router.push('/home/courseList')
                                    router.push()
                                } else {
                                    ElMessage({
                                        showClose: true,
                                        message: '失败',
                                        type: "warning",
                                    });
                                }
                            })

            }
        };

        return {
            ...toRefs(state),
            ...methods,
            ruleFormRef,
            ruleFormRefjoint,
            rulesexercisesRef
        }
    },
    components: { ArrowLeftBold, Delete },
}
</script>
<style  lang="scss" scoped>
#courseIntroductionDetails {
    height: 100%;
    position: relative;
    background: #F7F8FA;

    .box {
        width: 66.5%;
        height: 100%;
        margin: 0 auto;
        padding: 0.5%;
    }
    .breadCrumbs{
        width: 66.5%;
        margin: 10px auto;
        font-size: 12px;
    }
    .tabClass {
        width: 66.5%;
        height: 40px;
        margin: 10px auto;
        background: #fff;
        display: flex;
        justify-content: space-around;
        align-items: center;
    }

    .activeTab {
        color: #76C8F6;
    }

    .avatar-uploader .avatar {
        width: 178px;
        height: 178px;
        display: block;
    }

    .block {
        display: flex;
        justify-content: flex-start
    }

    :deep(.courseName) {
        input::-webkit-input-placeholder {
            /* placeholder颜色 */
            /* placeholder字体大小 */
            font-size: 12px;
        }

        input {
            width: 124px;
            height: 30px;
        }
    }

    .line-content {
        input {
            height: 40px;
            line-height: 40px;
            width: 100%;
            box-sizing: border-box;
            padding: 0 20px;
            border: 1px solid #c6c6c6;
            border-radius: 4px;
            background: #fff;
            font-size: 14px;
            color: #3c3c3c;

            &.hiddeiput {
                opacity: 0;
                position: absolute;
                width: 100%;
                height: 100%;
                top: 50%;
                left: 0;
            }
        }
    }

}
</style>
<style>
.el-form-item__content {
    line-height: 20px !important;

}

.el-form-item {
    align-items: center !important;
}

.upload-demo {
    width: 495px;
    height: 281px;
    border-radius: 4px;
    border: 1px solid #E1E1E1;
    float: left;
}
.el-upload-dragger{
    width: 300px !important;
    text-align: left !important;
    border: none !important;
    height: 70px !important;
}
.el-input__prefix {
    right: -80%;
}

.el-icon-date {
    border-left: 1px solid #e7e7e7;
}

.el-textarea__inner {
    min-height: 250px !important;
    max-height: 250px !important;
}

.avatar-uploader .el-upload {
    border: 1px dashed var(--el-border-color);
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    transition: var(--el-transition-duration-fast);
}

.avatar-uploader .el-upload:hover {
    border-color: var(--el-color-primary);
}

.el-icon.avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 84px;
    height: 84px;
    text-align: center;
    border: 1px solid #979797;
    border-radius: 50%;
    background: #F7F8FA;
    display: flex;
    align-items: center;
    justify-content: center;
}
</style>
