import axios from 'axios';
import {baseUrl} from '@/js/util.js'
// 获取当前班级当前课程的章节内容
export const f_get_classNumCourseContent=(datas)=>{
    return axios({
        method:'get',
        url:`${baseUrl}/Training_API/api/F_teacherUser/f_get_classNumCourseContent`,
        params:datas,
        headers: {'isFrontEnt':true},
    })
}
// 获取班级统计数据
export const f_get_classNumStatistics=(datas)=>{
    return axios({
        method:'get',
        url:`${baseUrl}/Training_API/api/F_teacherUser/f_get_classNumStatistics`,
        params:datas,
        headers: {'isFrontEnt':true},
    })
}
// 删除班级内的规则
export const f_delete_courseExercises=(datas)=>{
    return axios({
        method:'post',
        url:`${baseUrl}/Training_API/api/F_teacherUser/f_delete_courseExercises`,
        data:datas,
        headers:{
            "Content-Type":"application/json"
        }
    })
}
// 获取章下拉列表
export const get_select_courseChapterList=(datas)=>{
    return axios({
        method:'get',
        url:`${baseUrl}/Training_API/api/Dictionary/get_select_courseChapterList`,
        params:datas,
        headers: {'isFrontEnt':true},
    })
}
// 获取节下拉列表
export const get_select_courseSectionList=(datas)=>{
    return axios({
        method:'get',
        url:`${baseUrl}/Training_API/api/Dictionary/get_select_courseSectionList`,
        params:datas,
        headers: {'isFrontEnt':true},
    })
}
// 配置习题作业
export const f_update_courseExercises=(datas)=>{
    return axios({
        method:'post',
        url:`${baseUrl}/Training_API/api/F_teacherUser/f_update_courseExercises`,
        data:datas,
    })
}
// 获取符合条件的试题数
export const get_topicCount=(datas)=>{
    return axios({
        method:'get',
        url:`${baseUrl}/Training_API/api/Dictionary/get_topicCount`,
        params:datas
    })
}
// 生成作业
export const f_intsert_generatePreviewPapers=(datas)=>{
    return axios({
        method:'post',
        url:`${baseUrl}/Training_API/api/F_teacherUser/f_intsert_generatePreviewPapers`,
        data:datas,
    })
}
// 获取班级中的考试记录
export const f_get_classNumExamination=(datas)=>{
    return axios({
        method:'get',
        url:`${baseUrl}/Training_API/api/F_teacherUser/f_get_classNumExamination`,
        params:datas
    })
}
// 获取班级中的考试详情
export const f_get_classNumExaminationDataPageList=(datas)=>{
    return axios({
        method:'get',
        url:`${baseUrl}/Training_API/api/F_teacherUser/f_get_classNumExaminationDataPageList`,
        params:datas
    })
}
// 获取班级中的排课列表
export const f_get_classNumTeacherTaskPageList=(datas)=>{
    return axios({
        method:'get',
        url:`${baseUrl}/Training_API/api/F_teacherUser/f_get_classNumTeacherTaskPageList`,
        params:datas
    })
}
// 获取上课教师下拉
export const get_select_classRoomList=(datas)=>{
    return axios({
        method:'get',
        url:`${baseUrl}/Training_API/api/Dictionary/get_select_classRoomList`,
        params:datas
    })
}
// 添加排课
export const f_update_teacherTask=(datas)=>{
    return axios({
        method:'post',
        url:`${baseUrl}/Training_API/api/F_teacherUser/f_update_teacherTask`,
        data:datas,
    })
}
// 下载模板
export const DownloadTeacherTaskExcel=(datas)=>{
    return axios({
        method:'get',
        url:`${baseUrl}/Training_API/api/F_teacherUser/DownloadTeacherTaskExcel`,
        params:datas,
        responseType: "blob",
        headers: {'isFrontEnt':true},
    })
}
// 获取排课内的课后作业
export const f_get_teacherTaskCourseExercises=(datas)=>{
    return axios({
        method:'get',
        url:`${baseUrl}/Training_API/api/F_teacherUser/f_get_teacherTaskCourseExercises`,
        params:datas
    })
}
// 导入排课
export const f_ImportExcel_teacherTask=(datas)=>{
    return axios({
        method:'post',
        data:datas,
        url:`${baseUrl}/Training_API/api/F_teacherUser/f_ImportExcel_teacherTask`,
        headers: {'isFrontEnt':true,'Content-Type': 'application/x-www-form-urlencoded'},
    })
}
// 作业生成
export const f_intsert_generateCoursePreviewPapers=(datas)=>{
    return axios({
        method:'post',
        url:`${baseUrl}/Training_API/api/F_teacherUser/f_intsert_generateCoursePreviewPapers`,
        data:datas,
    })
}
// 获取系统体验的作业数据
export const f_get_systemExperienceExercises=(datas)=>{
    return axios({
        method:'get',
        url:`${baseUrl}/Training_API/api/F_teacherUser/f_get_systemExperienceExercises`,
        params:datas
    })
}
// 替换试题
export const f_update_replaceQuestions=(datas)=>{
    return axios({
        method:'get',
        url:`${baseUrl}/Training_API/api/F_teacherUser/f_update_replaceQuestions`,
        params:datas
    })
}